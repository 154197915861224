import React from 'react';

import imageOne from '../../../assets/img/common/funding/national/imgActionForFoodProduction.jpg';
import imageTwo from '../../../assets/img/common/funding/national/imgHelpAgeIndia.jpg';
import imageThree from '../../../assets/img/common/funding/national/imgSirDorabjiTataTrust.jpg';
import imageFour from '../../../assets/img/common/funding/national/imgChanduMemorialTrust.jpg';
import imageFive from '../../../assets/img/common/funding/national/imgCaritasIndia.jpg';
import imageSix from '../../../assets/img/common/funding/national/imgDhristi.jpg';
import imageSeven from '../../../assets/img/common/funding/national/imgHolisticChiledDevelopment.jpg';
import imageEight from '../../../assets/img/common/funding/national/imgChurchAuxiliarySocialAction.jpg';
import imageNine from '../../../assets/img/common/funding/national/imgAndheriTrust.jpg';
import bgImage from '../../../assets/img/common/imgPageHeader.jpg';

const NationalAgencies = () => (
   <div>
      <section className="inner-header" style={{backgroundImage: `url(${bgImage})`}}>
         <div className="container">
            <div className="row">
               <div className="col-md-12 sec-title colored text-center">
                  <h2>Gallery</h2>
                  <ul className="breadcumb">
                     <li><a href="index.html">Home</a></li>
                     <li><i className="fa fa-angle-right"></i></li>
                     <li><span>National NGOs</span></li>
                  </ul>
                  <span className="decor"><span className="inner"></span></span>
               </div>
            </div>
         </div>
      </section>


      <section className="gallery-section pb_70">
         <div className="container">

            <div className="clearfix">

               <div className="image-box">
                  <div className="inner-box">
                     <figure className="image">
                        <img src="/img/common/funding/national/imgActionForFoodProduction.jpg" alt="Action for Food Production – Udaipur" title="Action for Food Production – Udaipur" />
                        <figcaption className="pt-5 text-center">Action for Food Production – Udaipur</figcaption>
                     </figure>
                  </div>
               </div>

               <div className="image-box">
                  <div className="inner-box">
                     <figure className="image">
                        <img src="/img/common/funding/national/imgHelpAgeIndia.jpg" alt="HelpAge India – New Delhi" title="HelpAge India – New Delhi" />
                        <figcaption className="pt-5 text-center">HelpAge India – New Delhi</figcaption>
                     </figure>
                  </div>
               </div>

               <div className="image-box">
                  <div className="inner-box">
                     <figure className="image">
                        <img src="/img/common/funding/national/imgSirDorabjiTataTrust.jpg" alt="Sir Dorabji Tata Trust – Mumbai" title="Sir Dorabji Tata Trust – Mumbai" />
                        <figcaption className="pt-5 text-center">Sir Dorabji Tata Trust – Mumbai</figcaption>
                     </figure>
                  </div>
               </div>

               <div className="image-box">
                  <div className="inner-box">
                     <figure className="image">
                        <img src="/img/common/funding/national/imgChanduMemorialTrust.jpg" alt="Chandu Memorial Trust – Mumbai" title="Chandu Memorial Trust – Mumbai" />
                        <figcaption className="pt-5 text-center">Chandu Memorial Trust – Mumbai</figcaption>
                     </figure>
                  </div>
               </div>

               <div className="image-box">
                  <div className="inner-box">
                     <figure className="image">
                        <img src="/img/common/funding/national/imgCaritasIndia.jpg" alt="Caritas India – New Delhi" title="Caritas India – New Delhi" />
                        <figcaption className="pt-5 text-center">Caritas India – New Delhi</figcaption>
                     </figure>
                  </div>
               </div>

               <div className="image-box">
                  <div className="inner-box">
                     <figure className="image">
                        <img src="/img/common/funding/national/imgDhristi.jpg" alt="Drishti – Mumbai" title="Drishti – Mumbai" />
                        <figcaption className="pt-5 text-center">Drishti – Mumbai</figcaption>
                     </figure>
                  </div>
               </div>

               <div className="image-box">
                  <div className="inner-box">
                     <figure className="image">
                        <img src="/img/common/funding/national/imgHolisticChiledDevelopment.jpg" alt="Holistic Child Development of India – Pune" title="Holistic Child Development of India – Pune" />
                        <figcaption className="pt-5 text-center">Holistic Child Development of India – Pune</figcaption>
                     </figure>
                  </div>
               </div>

               <div className="image-box">
                  <div className="inner-box">
                     <figure className="image">
                        <img src="/img/common/funding/national/imgChurchAuxiliarySocialAction.jpg" alt="Church’s Auxiliary For Social Action – Mumbai" title="Church’s Auxiliary For Social Action – Mumbai" />
                        <figcaption className="pt-5 text-center">Church’s Auxiliary For Social Action – Mumbai</figcaption>
                     </figure>
                  </div>
               </div>

               <div className="image-box">
                  <div className="inner-box">
                     <figure className="image">
                        <img src="/img/common/funding/national/imgAndheriTrust.jpg" alt="Andheri Trust – Tamil Nadu" title="Andheri Trust – Tamil Nadu" />
                        <figcaption className="pt-5 text-center">Andheri Trust – Tamil Nadu</figcaption>
                     </figure>
                  </div>
               </div>

            </div>
         </div>
      </section>
   </div>
);
export default NationalAgencies;