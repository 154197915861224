import React from 'react';

const HomePageEvents = () => {
  return (
    <div className="col-sm-12 col-md-6">
      <div className="sec-title style-two" data-margin-bottom="20px">
        <h2>Upcoming Event</h2>
        <span className="decor">
          <span className="inner"></span>
        </span>
      </div>
      {/*<div>
        <div className="event style-two">
          <div className="row">
            <div className="col-xs-2">
              <div className="event-thumb">
                <ul className="event-date">
                  <li className="date">09</li>
                  <li className="month">Dec</li>
                </ul>
              </div>
            </div>
            <div className="col-xs-10">
              <div className="event-content">
                <h5 className="event-title"><a href="#">31<sup>st</sup>All Kutch Meet for the Differently Abled</a></h5>
                <ul className="event-held list-inline">
                  <li className="mr-10" data-text-color="#555"><i className="fa fa-clock-o"></i> 12.00 pm</li>
                  <li data-text-color="#555"> <i className="fa fa-map-marker"></i> Bhuj.</li>
                </ul>
                <p className="mb-0">31<sup>st</sup> All Kutch Meet for the Differently Abled at 12.00 pm on Sunday 9th December 2018.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="event style-two">
          <div className="row">
            <div className="col-xs-2">
              <div className="event-thumb">
                <ul className="event-date">
                  <li className="date">09</li>
                  <li className="month">Dec</li>
                </ul>
              </div>
            </div>
            <div className="col-xs-10">
              <div className="event-content">
                <h5 className="event-title"><a href="#">31<sup>st</sup>All Kutch Meet for the Differently Abled</a></h5>
                <ul className="event-held list-inline">
                  <li className="mr-10" data-text-color="#555"><i className="fa fa-clock-o"></i> 12.00 pm</li>
                  <li data-text-color="#555"> <i className="fa fa-map-marker"></i> Bhuj.</li>
                </ul>
                <p className="mb-0">31<sup>st</sup> All Kutch Meet for the Differently Abled at 12.00 pm on Sunday 9th December 2018.</p>
              </div>
            </div>
          </div>
        </div>
      </div>*/}
    </div>
  );
};
export default HomePageEvents;
