import React from 'react';

import imageOne from '../../../assets/img/common/inners/EYE-CARE-ACTIVITIES.jpg';
import imageTwo from '../../../assets/img/common/inners/PREVENTION-OF-BLINDNESS.jpg';
import imageThree from '../../../assets/img/common/inners/SCHOOL-EYE-SCREENING.jpg';
import imageFour from '../../../assets/img/common/inners/VISION-2020-RIGHT-TO-SIGHT.jpg';
import imageFive from '../../../assets/img/common/inners/ADULT-EYE-SCREENING.jpg';
import imageSix from '../../../assets/img/common/inners/GENERAL-MEDICINE.jpg';
import imageSeven from '../../../assets/img/common/inners/PHYSIOTHERAPY.jpg';
import imageEight from '../../../assets/img/common/inners/MOTHER-CHILD-HEALTH-CARE.jpg';

import bgImage from '../../../assets/img/common/imgPageHeader.jpg';


const HealthCareActivities = () => (
   <div>
      <section className="inner-header" style={{backgroundImage: `url(${bgImage})`}}>
         <div className="container">
            <div className="row">
               <div className="col-md-12 sec-title colored text-center">
                  <h2>Activities</h2>
                  <ul className="breadcumb">
                     <li><a href="index">Home</a></li>
                     <li><i className="fa fa-angle-right"></i></li>
                     <li><span>Health Care Activities</span></li>
                  </ul>
                  <span className="decor"><span className="inner"></span></span>
               </div>
            </div>
         </div>
      </section>


      <section className="event-feature sec-padding pb_60" data-bg-color="#fafafa">
         <div className="container">
            <div className="row">


               <div className="col-sm-12">
                  <div className="event border-1px mb_30">
                     <div className="row">
                        <div className="col-sm-5">
                           <div className="event-thumb">
                              <div className="thumb">
                                 <img className="full-width" src="/img/common/inners/EYE-CARE-ACTIVITIES.jpg" alt="EYE CARE ACTIVITIES"/>
									</div>
                              </div>
                           </div>
                           <div className="col-sm-7">
                              <div className="event-content p_20 pl_0 pt_5">
                                 <h3 className="event-title">EYE CARE ACTIVITIES</h3>
                                 <p className="mb_20">Health care is one of the main areas of priority for Kutch Vikas Trust. The health
                                 scenario in Kutch District is not encouraging as a whole. With an aim to provide quality medical
                                 and health care to the people in the villages around, K. V. T. has undertaken many programmes.
                                 Eye-related problems are steadily on the rise in this part of the country. And so, eye care
                                 activities are a priority with, all the means of eye health and eye screening programmes,
										all over the district.</p>
                                 <p className="mb_20">The daily eye camps screen the patients for eye-related problems, and the
                                 different OPD’s at different cities and towns cater to the people at their doorstep.
                                 On an average, we conduct 300 surgeries per month. And operations go up every year,
										with more outreach camps.</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className="col-sm-12">
                     <div className="event border-1px mb_30">
                        <div className="row">
                           <div className="col-sm-5">
                              <div className="event-thumb">
                                 <div className="thumb">
                                    <img className="full-width" src="/img/common/inners/PREVENTION-OF-BLINDNESS.jpg" alt="PREVENTION OF BLINDNESS"/>
									</div>
                                 </div>
                              </div>
                              <div className="col-sm-7">
                                 <div className="event-content p_20 pl_0 pt_5">
                                    <h3 className="event-title">PREVENTION OF BLINDNESS</h3>
                                    <p className="mb_20">One of the major initiatives undertaken by Kutch Vikas Trust
                                    includes prevention of blindness, through various interventions. Our major
                                    initiatives include, survey of the blind, daily eye camps, distribution of
                                    Vitamin A tablets, School screening for children with visual problems,
                                    awareness camps for prevention of blindness etc. We have also taken up
                                    Comprehensive Eye Health Service in this district, wherein we have the
                                    task of survey, Eye Camps, Community based rehabilitation, Integration
                                    in other schools and Itinerary Teachers to visually challenged children.
                                    We could reach different parts of the Districts, where by generating
										awareness and instilling confidence among the masses.</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>


                     <div className="col-sm-12">
                        <div className="event border-1px mb_30">
                           <div className="row">
                              <div className="col-sm-5">
                                 <div className="event-thumb">
                                    <div className="thumb">
                                       <img className="full-width" src="/img/common/inners/SCHOOL-EYE-SCREENING.jpg" alt="SCHOOL EYE SCREENING"/>
									</div>
                                    </div>
                                 </div>
                                 <div className="col-sm-7">
                                    <div className="event-content p_20 pl_0 pt_5">
                                       <h3 className="event-title">SCHOOL EYE SCREENING</h3>
                                       <p className="mb_20">Children in the schools of entire Kutch are helped through this programme.
                                       Teachers are given special training in Eye care methods and children are treated
										for refractive errors and surgical proceedings are done those who need them.</p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>


                        <div className="col-sm-12">
                           <div className="event border-1px mb_30">
                              <div className="row">
                                 <div className="col-sm-5">
                                    <div className="event-thumb">
                                       <div className="thumb">
                                          <img className="full-width" src="/img/common/inners/VISION-2020-RIGHT-TO-SIGHT.jpg" alt="VISION 2020, RIGHT TO SIGHT"/>
										</div>
                                       </div>
                                    </div>
                                    <div className="col-sm-7">
                                       <div className="event-content p_20 pl_0 pt_5">
                                          <h3 className="event-title">VISION 2020, RIGHT TO SIGHT</h3>
                                          <p className="mb_20">K. V. T. is also the partner in the Global fight against avoidable blindness.
                                          We also actively participate in the programmes and policies of the forum for such move,
											which is called, Vision 2020, Right to Sight India.</p>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>


                           <div className="col-sm-12">
                              <div className="event border-1px mb_30">
                                 <div className="row">
                                    <div className="col-sm-5">
                                       <div className="event-thumb">
                                          <div className="thumb">
                                             <img className="full-width" src="/img/common/inners/ADULT-EYE-SCREENING.jpg" alt="ADULT EYE SCREENING"/>
											</div>
                                          </div>
                                       </div>
                                       <div className="col-sm-7">
                                          <div className="event-content p_20 pl_0 pt_5">
                                             <h3 className="event-title">ADULT EYE SCREENING</h3>
                                             <p className="mb_20">We also have a full-time Adult eye screening team, who go to the villages of Kutch and
                                             conduct eye screening camps. All those who are detected with any of the eye problems are treated
												free of cost.</p>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>


                              <div className="col-sm-12">
                                 <div className="event border-1px mb_30">
                                    <div className="row">
                                       <div className="col-sm-5">
                                          <div className="event-thumb">
                                             <div className="thumb">
                                                <img className="full-width" src="/img/common/inners/GENERAL-MEDICINE.jpg" alt="GENERAL MEDICINE"/>
												</div>
                                             </div>
                                          </div>
                                          <div className="col-sm-7">
                                             <div className="event-content p_20 pl_0 pt_5">
                                                <h3 className="event-title">GENERAL MEDICINE</h3>
                                                <p className="mb_20">In order to give succor to the people in the nearby villages, we provide primary
                                                health services and health camps in the villages. A permanent resident doctor caters to the
													health needs of the people.</p>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>


                                 <div className="col-sm-12">
                                    <div className="event border-1px mb_30">
                                       <div className="row">
                                          <div className="col-sm-5">
                                             <div className="event-thumb">
                                                <div className="thumb">
                                                   <img className="full-width" src="/img/common/inners/PHYSIOTHERAPY.jpg" alt="PHYSIOTHERAPY"/>
													</div>
                                                </div>
                                             </div>
                                             <div className="col-sm-7">
                                                <div className="event-content p_20 pl_0 pt_5">
                                                   <h3 className="event-title">PHYSIOTHERAPY</h3>
                                                   <p className="mb_20">The devastating earthquake has made a lot of people invalid and disabled. We cater
                                                   to their needs with two centers at Bhuj and Nakhatrana. They are given all the advanced
														physiotherapeutic treatment at the subsidized cost.</p>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>

                                    <div className="col-sm-12">
                                       <div className="event border-1px mb_30">
                                          <div className="row">
                                             <div className="col-sm-5">
                                                <div className="event-thumb">
                                                   <div className="thumb">
                                                      <img className="full-width" src="/img/common/inners/MOTHER-CHILD-HEALTH-CARE.jpg" alt="MOTHER & CHILD HEALTH CARE"/>
														</div>
                                                   </div>
                                                </div>
                                                <div className="col-sm-7">
                                                   <div className="event-content p_20 pl_0 pt_5">
                                                      <h3 className="event-title">MOTHER & CHILD HEALTH CARE</h3>
                                                      <p className="mb_20">In order to help the poor women and children in the nearby villages, K. V. T.
                                                      has come forward to provide them nutritious supplements for their all-around health
															and growth.</p>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>

                                 </div>
	</section>


                           </div>
);

export default HealthCareActivities;