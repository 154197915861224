import React from 'react';
import { Link } from 'react-router-dom';

import FooterLatestNews from './footerLatestNews.component';
import FooterBottom from './footerbottom.component';

const FooterComponent = () => (
	<div>
		<footer className="footer sec-padding">
			<div className="container">
				<div className="row">
					<div className="col-md-5 col-sm-6">
						<div className="footer-widget about-widget">
							<Link to="/">
								<img src="img/common/logoWhite.png" alt="KVT Logo" />
							</Link>
							<p>This is non-political, non-profit and non-sectarian in nature.
							The services of Kutch Vikas Trust are available to all sections
							of the people, without any discrimination of caste,
							religion and social status.</p>
							<ul className="contact">
								<li><i className="fa fa-map-marker"></i> <span>Raidhanpar Village, Nagor P.O.<br />
									Bhuj - 370 001. <br />
									Kutch. Gujarat. INDIA.</span></li>
								<li><i className="fa fa-phone"></i> <span>+91, 2832, 274289, 274230, 274286</span></li>
								<li><i className="fa fa-envelope-o"></i> <span>kutchvikastrust@gmail.com</span></li>
							</ul>
							<ul className="social">
								<li><a href="https://www.facebook.com/KutchVikasTrust/"><i className="fa fa-facebook"></i></a></li>
								<li><a href="#"><i className="fa fa-twitter"></i></a></li>
								<li><a href="#"><i className="fa fa-instagram"></i></a></li>
							</ul>
						</div>
					</div>
					<div className="col-md-2 col-sm-6">
						<div className="footer-widget quick-links">
							<h3 className="title">Pages</h3>
							<ul>
								<li><Link to="/an-appeal">An Appeal</Link></li>
								<li><Link to="/about-us/kutch-vikas-trust">Kutch Vikas Trust</Link></li>
								<li><Link to="/about-us/fr-george-kunnath">Fr. George Kunnath</Link></li>
								<li><Link to="/about-us/photo-gallery">Photo Gallery</Link></li>
								<li><Link to="/about-us/statistics">Statistics</Link></li>
								<li><Link to="/about-us/awards">Awards</Link></li>
							</ul>
						</div>
					</div>
					<div className="col-md-5 latest-post col-sm-6">
						<div className="footer-widget latest-post">
							<h3 className="title">Latest News</h3>
							<FooterLatestNews />
						</div>
					</div>
				</div>
			</div>
		</footer>
		<FooterBottom />
	</div>
);

export default FooterComponent;