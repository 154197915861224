export const photos = [
    {
      src: "http://www.kutchvikastrust.org/img/common/imgHomeSect3.jpg",
      width: 3,
      height: 5
    },
    {
      src: "http://www.kutchvikastrust.org/img/common/sliders/banner2.jpg",
      width: 6,
      height: 3
    },
    {
      src: "http://www.kutchvikastrust.org/img/common/inners/aboutImage1.jpg",
      width: 9,
      height: 3
    },
    {
      src: "http://www.kutchvikastrust.org/img/common/awards/Bharat-Excellence.jpg",
      width: 6,
      height: 4
    },
    {
      src: "http://www.kutchvikastrust.org/img/common/awards/Best-Employer-of-Disabled.jpg",
      width: 6,
      height: 4
    },
    {
      src: "http://www.kutchvikastrust.org/img/common/inners/HOSTEL-FOR-THE-DISABLED.jpg",
      width: 6,
      height: 4
    },
    {
      src: "http://www.kutchvikastrust.org/img/common/inners/ADULT-EDUCATION.jpg",
      width: 5,
      height: 3
    },
    {
      src: "http://www.kutchvikastrust.org/img/common/inners/ITINERANT-TEACHERS.jpg",
      width: 5,
      height: 3
    },
    {
      src: "http://www.kutchvikastrust.org/img/common/inners/EYE-CARE-ACTIVITIES.jpg",
      width: 4,
      height: 3
    },
    {
      src: "http://www.kutchvikastrust.org/img/common/inners/PREVENTION-OF-BLINDNESS.jpg",
      width: 4,
      height: 3
    },
    {
      src: "http://www.kutchvikastrust.org/img/common/inners/SCHOOL-EYE-SCREENING.jpg",
      width: 4,
      height: 3
    },
    {
      src: "http://www.kutchvikastrust.org/img/common/inners/VISION-2020-RIGHT-TO-SIGHT.jpg",
      width: 4,
      height: 3
    },
    {
      src: "http://www.kutchvikastrust.org/img/common/inners/ADULT-EYE-SCREENING.jpg",
      width: 4,
      height: 3
    },
    {
      src: "http://www.kutchvikastrust.org/img/common/inners/GENERAL-MEDICINE.jpg",
      width: 4,
      height: 3
    },
    {
      src: "http://www.kutchvikastrust.org/img/common/inners/PHYSIOTHERAPY.jpg",
      width: 4,
      height: 3
    },
    {
      src: "http://www.kutchvikastrust.org/img/common/inners/MOTHER-CHILD-HEALTH-CARE.jpg",
      width: 4,
      height: 3
    },
    {
      src: "http://www.kutchvikastrust.org/img/common/imgHomeSect5.jpg",
      width: 4,
      height: 3
    },
    {
      src: "http://www.kutchvikastrust.org/img/common/inners/MARHARSHI-ASTAVAKR-YOJANA.jpg",
      width: 4,
      height: 3
    },
    {
      src: "http://www.kutchvikastrust.org/img/common/inners/COMMUNITY-BASED-REHABILITATION.jpg",
      width: 4,
      height: 3
    },
    {
      src: "http://www.kutchvikastrust.org/img/common/inners/ITINERANT-TEACHERS.jpg",
      width: 4,
      height: 3
    },
    {
      src: "http://www.kutchvikastrust.org/img/common/inners/SELF-HELP-GROUPS.jpg",
      width: 4,
      height: 3
    },
    {
      src: "http://www.kutchvikastrust.org/img/common/inners/INCOME-GENERATION-PROGRAMMES.jpg",
      width: 4,
      height: 3
    },
    {
      src: "http://www.kutchvikastrust.org/img/common/inners/SELF-HELP-GROUPS.jpg",
      width: 4,
      height: 3
    },
    {
      src: "http://www.kutchvikastrust.org/img/common/inners/ANNUAL-SPORTS-AND-GAMES-FOR-THE-DISABLED.jpg",
      width: 4,
      height: 3
    },
    {
      src: "http://www.kutchvikastrust.org/img/common/inners/CRICKET-TOURNAMENT-FOR-THE-VILLAGES.jpg",
      width: 4,
      height: 3
    },
    {
      src: "http://www.kutchvikastrust.org/img/common/inners/RAS-GARBA-COMPETITION.jpg",
      width: 4,
      height: 3
    },
    {
      src: "http://www.kutchvikastrust.org/img/common/inners/Maharshi-Ashtavakra-Yogna.jpg",
      width: 4,
      height: 3
    }
  ];
  