import React from 'react';
import { Link } from 'react-router-dom';

import bgImage from '../../../assets/img/call-to-action/left-box-bg.jpg';
import bgImage1 from '../../../assets/img/common/imgPageHeader.jpg';




const AnAppealComponent = () => (
   <div>
      <section className="inner-header" style={{backgroundImage: `url(${bgImage1})`}}>
        <div className="container">
         <div className="row">
            <div className="col-md-12 sec-title colored text-center">
               <h2>An Appeal</h2>
               <ul className="breadcumb">
                  <li><a href="index.html">Home</a></li>
                  <li><i className="fa fa-angle-right"></i></li>
                  <li><span>An Appeal</span></li>
               </ul>
               <span className="decor"><span className="inner"></span></span>
            </div>
         </div>
      </div>
    </section>


   <section className="sec-padding about-content full-sec clsComPB50">
      <div className="container">
         <div className="row">

            <div className="col-md-12">
               <div className="full-sec-content">
                  <div className="sec-title style-two">
                     <h2>An Appeal</h2>
                     <span className="decor">
                        <span className="inner"></span>
                     </span>
                  </div>

                  <br />
                  <p>
                     You can also be part of our effort towards making a difference in the lives of hundreds of poor and disabled. Donation to Kutch Vikas Trust is exempted from Income Tax under 80 G for 50% tax concession, and 35 AC for 100% tax exemption under the income tax act. By becoming partners with Kutch Vikas Trust you can make a disabled person happier and more meaningful in the society.
                        </p>
                  <br />
                  <h3>Donate as under:</h3>
                  <br />
                  <ol>
                     <li>Adopt a Disabled Student for 1 year by paying Rs.8000/-.<br />&nbsp;</li>
                     <li>Adopt the education and upbringing of a disabled child until he/she completes school final by paying Rs.8000/- year.<br />&nbsp;</li>
                     <li>Adopt a Senior Citizen for 20,000/- year.<br />&nbsp;</li>
                     <li>Sponsor Eye Surgeries (Cataract Operations) for Rs.1500/- surgery.<br />&nbsp;</li>
                     <li>Sponsor Eye Screening camps for Rs.10,000/- camp with medicines, spectacles free of cost.<br />&nbsp;</li>
                     <li>Sponsor one time meals at Rs.3000/- for the Hostel Students.<br />&nbsp;</li>
                  </ol>
                  <br />
                  <p><strong>
                     Make DD / Cheque in favor of <span style={{fontSize: 18 + 'px'}}>“Kutch Vikas Trust”</span> payable at Bhuj.
                            </strong></p>
                  <br />
               </div>
               <section className="call-to-action">
                  <div className="container">
                     <div className="clearfix">
                        <div className="call-to-action-corner col-md-6" style={{backgroundImage: 'url(img/common/imgHomeSect1.jpg)'}}>
                           <div className="single-call-to-action">

                              <div className="content-box">
                                 <h3>Click Here</h3>
                                 <p>to email us the donation form</p>
                                 <Link to="/an-appeal" className="thm-btn inverse">Click Here</Link>
                              </div>
                           </div>
                        </div>
                        <div className="call-to-action-corner col-md-6" style={{backgroundImage: 'url(img/common/imgHomeSect2.jpg)'}}>
                           <div className="single-call-to-action">

                              <div className="content-box">
                                 <h3>Click Here</h3>
                                 <p>to send snail mail us the donation form</p>
                                 <a href="/img/common/kvt_donationform.pdf" target="_blank" className="thm-btn inverse">Click Here</a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
            </div>
         </div>
      </div>
   </section>
   </div >
);
export default AnAppealComponent;