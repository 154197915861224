import React from 'react';
import { Link } from 'react-router-dom';

const HeaderNavComponent = () => (
	<nav className="mainmenu-area stricky">
		<div className="container">
			<div className="navigation pull-left">
				<div className="nav-header">
					<ul>
						<li><Link to="/">Home</Link></li>
						<li className="dropdown">
							<Link to="/">About Us</Link>
							<ul className="submenu">
								<li><Link to="/about-us/kutch-vikas-trust">Kutch Vikas Trust</Link></li>
								<li><Link to="/about-us/fr-george-kunnath">Fr. George Kunnath</Link></li>
								<li><Link to="/about-us/photo-gallery">Photo Gallery</Link></li>
								<li><Link to="/about-us/statistics">Statistics</Link></li>
								<li><Link to="/about-us/awards">Awards</Link></li>
								{/*<li><Link to="/about-us/events">Events</Link></li>
								<li><Link to="/about-us/news">News</Link></li>*/}
							</ul>
						</li>
						<li className="dropdown">
							<Link to="/">Activities</Link>
							<ul className="submenu">
								<li><Link to="/activities/education-for-the-disabled">Education for the Disabled</Link></li>
								<li><Link to="/activities/health-care-activities">Health Care Activities</Link></li>
								<li><Link to="/activities/community-based-rehab">Community Based Rehab</Link></li>
								<li><Link to="/activities/center-for-the-multi-disabled">Center for the Multi-disabled</Link></li>
								<li><Link to="/activities/other-welfare-activities">Other Welfare Activities</Link></li>
							</ul>
						</li>
						<li className="dropdown">
							<Link to="/">Projects</Link>
							<ul className="submenu">
								<li><Link to="/projects/maharshi-ashtavakra-yogna">Maharshi Ashtavakra Yogna</Link></li>
								<li><Link to="/projects/watershed-management">Watershed Management</Link></li>
								<li><Link to="/projects/ces-anjar-project">CES Anjar Project</Link></li>
							</ul>
						</li>
						<li className="dropdown">
							<Link to="/">Funding Agenesis</Link>
							<ul className="submenu">
								<li><Link to="/funding-agenesis/government-agencies">Government Agencies</Link></li>
								<li><Link to="/funding-agenesis/national-ngos">National NGOs</Link></li>
								<li><Link to="/funding-agenesis/international-ngos">International NGOs</Link></li>
							</ul>
						</li>
						<li><Link to="/an-appeal">An Appeal</Link></li>
					</ul>
				</div>
				<div className="nav-footer">
					<button><i className="fa fa-bars"></i></button>
				</div>
			</div>
		</div>
	</nav>);

export default HeaderNavComponent;