import React from 'react';

import imageOne from '../../../assets/img/common/funding/international/imgChristoffelBlindenmission.jpg';
import imageTwo from '../../../assets/img/common/funding/international/imgSenceInternational.jpg';
import imageThree from '../../../assets/img/common/funding/international/imgDKBWDeutschesKatholisches.jpg';
import imageFour from '../../../assets/img/common/funding/international/imgBDKJKinderMissionwork.jpg';
import imageFive from '../../../assets/img/common/funding/international/imgAndheriHilfe.jpg';
import imageSix from '../../../assets/img/common/funding/international/imgLittleWayAssociation.jpg';
import imageSeven from '../../../assets/img/common/funding/international/imgScalabriniCharitiesforthePoor.jpg';
import imageEight from '../../../assets/img/common/funding/international/imgMiserior.jpg';
import imageNine from '../../../assets/img/common/funding/international/imgBliendFoundationOfIndia.jpg';
import imageTen from '../../../assets/img/common/funding/international/imgTrocaire.jpg';
import imageEleven from '../../../assets/img/common/funding/international/imgOrderMalta.jpg';
import imageTwelve from '../../../assets/img/common/funding/international/imgCIAL.jpg';
import imageThirteen from '../../../assets/img/common/funding/international/imgCECI.jpg';
import imageFourteen from '../../../assets/img/common/funding/international/imgIndianChristianAssociation.jpg';
import imageFifteen from '../../../assets/img/common/funding/international/imgMissio.jpg';
import imageSixteen from '../../../assets/img/common/funding/international/imgSightSavers.jpg';
import imageSeventeen from '../../../assets/img/common/funding/international/imgCatholicReliefSevice.jpg';

import bgImage from '../../../assets/img/common/imgPageHeader.jpg';


const InterNationalAgencies = () => (
	<div>
		<section className="inner-header" style={{backgroundImage: `url(${bgImage})`}}>
			<div className="container">
				<div className="row">
					<div className="col-md-12 sec-title colored text-center">
						<h2>Gallery</h2>
						<ul className="breadcumb">
							<li><a href="index.html">Home</a></li>
							<li><i className="fa fa-angle-right"></i></li>
							<li><span>International NGOs</span></li>
						</ul>
						<span className="decor"><span className="inner"></span></span>
					</div>
				</div>
			</div>
		</section>


		<section className="gallery-section pb_70">
			<div className="container">

				<div className="clearfix">

					<div className="row">

						<div className="image-box">
							<div className="inner-box">
								<figure className="image">
									<img src="/img/common/funding/international/imgChristoffelBlindenmission.jpg" alt="Christoffel Blindenmission – Germany" title="Christoffel Blindenmission – Germany" />
									<figcaption className="pt-5 text-center">Christoffel Blindenmission – Germany</figcaption>
								</figure>
							</div>
						</div>

						<div className="image-box">
							<div className="inner-box">
								<figure className="image">
									<img src="/img/common/funding/international/imgSenceInternational.jpg" alt="Sense International – U.K." title="Sense International – U.K." />
									<figcaption className="pt-5 text-center">Sense International – U.K.</figcaption>
								</figure>
							</div>
						</div>

						<div className="image-box">
							<div className="inner-box">
								<figure className="image">
									<img src="/img/common/funding/international/imgDKBWDeutschesKatholisches.jpg" alt="D. K. B. W (Deutsches Katholisches) – Germany" title="D. K. B. W (Deutsches Katholisches) – Germany" />
									<figcaption className="pt-5 text-center">D. K. B. W (Deutsches Katholisches) – Germany</figcaption>
								</figure>
							</div>
						</div>

						<div className="image-box">
							<div className="inner-box">
								<figure className="image">
									<img src="/img/common/funding/international/imgBDKJKinderMissionwork.jpg" alt="B. D. K. J. (Kindermissionswerk) – Germany" title="B. D. K. J. (Kindermissionswerk) – Germany" />
									<figcaption className="pt-5 text-center">B. D. K. J. (Kindermissionswerk) – Germany</figcaption>
								</figure>
							</div>
						</div>

					</div>

					<div className="row">
						<div className="image-box">
							<div className="inner-box">
								<figure className="image">
									<img src="/img/common/funding/international/imgAndheriHilfe.jpg" alt="Andheri Hilfe – Germany" title="Andheri Hilfe – Germany" />
									<figcaption className="pt-5 text-center">Andheri Hilfe – Germany</figcaption>
								</figure>
							</div>
						</div>

						<div className="image-box">
							<div className="inner-box">
								<figure className="image">
									<img src="/img/common/funding/international/imgLittleWayAssociation.jpg" alt="Little Way Association – U.K." title="Little Way Association – U.K." />
									<figcaption className="pt-5 text-center">Little Way Association – U.K.</figcaption>
								</figure>
							</div>
						</div>

						<div className="image-box">
							<div className="inner-box">
								<figure className="image">
									<img src="/img/common/funding/international/imgScalabriniCharitiesforthePoor.jpg" alt="Scalabrini Charities for the Poor – Canada" title="Scalabrini Charities for the Poor – Canada" />
									<figcaption className="pt-5 text-center">Scalabrini Charities for the Poor – Canada</figcaption>
								</figure>
							</div>
						</div>

						<div className="image-box">
							<div className="inner-box">
								<figure className="image">
									<img src="/img/common/funding/international/imgMiserior.jpg" alt="Miserior – Germany" title="Miserior – Germany" />
									<figcaption className="pt-5 text-center">Miserior – Germany</figcaption>
								</figure>
							</div>
						</div>
					</div>

					<div className="row">

						<div className="image-box">
							<div className="inner-box">
								<figure className="image">
									<img src="/img/common/funding/international/imgBliendFoundationOfIndia.jpg" alt="Blind Foundation for India – USA" title="Blind Foundation for India – USA" />
									<figcaption className="pt-5 text-center">Blind Foundation for India – USA</figcaption>
								</figure>
							</div>
						</div>

						<div className="image-box">
							<div className="inner-box">
								<figure className="image">
									<img src="/img/common/funding/international/imgTrocaire.jpg" alt="Troicare – Ireland" title="Troicare – Ireland" />
									<figcaption className="pt-5 text-center">Troicare – Ireland</figcaption>
								</figure>
							</div>
						</div>

						<div className="image-box">
							<div className="inner-box">
								<figure className="image">
									<img src="/img/common/funding/international/imgOrderMalta.jpg" alt="Order of Malta – France" title="Order of Malta – France" />
									<figcaption className="pt-5 text-center">Order of Malta – France</figcaption>
								</figure>
							</div>
						</div>

						<div className="image-box">
							<div className="inner-box">
								<figure className="image">
									<img src="/img/common/funding/international/imgCIAL.jpg" alt="C. I. A. I. – Italy" title="C. I. A. I. – Italy" />
									<figcaption className="pt-5 text-center">C. I. A. I. – Italy</figcaption>
								</figure>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="image-box">
							<div className="inner-box">
								<figure className="image">
									<img src="/img/common/funding/international/imgCECI.jpg" alt="C. E. C. I. – Canada" title="C. E. C. I. – Canada" />
									<figcaption className="pt-5 text-center">C. E. C. I. – Canada</figcaption>
								</figure>
							</div>
						</div>

						<div className="image-box">
							<div className="inner-box">
								<figure className="image">
									<img src="/img/common/funding/international/imgIndianChristianAssociation.jpg" alt="Indian Christian Association – England" title="Indian Christian Association – England" />
									<figcaption className="pt-5 text-center">Indian Christian Association – England</figcaption>
								</figure>
							</div>
						</div>

						<div className="image-box">
							<div className="inner-box">
								<figure className="image">
									<img src="/img/common/funding/international/imgMissio.jpg" alt="Missio – Germany" title="Missio – Germany" />
									<figcaption className="pt-5 text-center">Missio – Germany</figcaption>
								</figure>
							</div>
						</div>

						<div className="image-box">
							<div className="inner-box">
								<figure className="image">
									<img src="/img/common/funding/international/imgSightSavers.jpg" alt="Sight Savers International – Jodhpur, India" title="Sight Savers International – Jodhpur, India" />
									<figcaption className="pt-5 text-center">Sight Savers International – Jodhpur, India</figcaption>
								</figure>
							</div>
						</div>
					</div>
					<div className="row">

						<div className="image-box">
							<div className="inner-box">
								<figure className="image">
									<img src="/img/common/funding/international/imgCatholicReliefSevice.jpg" alt="Catholic Relief Service – Ahmedabad, India" title="Catholic Relief Service – Ahmedabad, India" />
									<figcaption className="pt-5 text-center">Catholic Relief Service – Ahmedabad, India</figcaption>
								</figure>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
);
export default InterNationalAgencies;