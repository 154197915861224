import React from 'react';

import imageOne from '../../../assets/img/common/stati/stat1.jpg';
import imageTwo from '../../../assets/img/common/stati/stat2.jpg';
import imageThree from '../../../assets/img/common/stati/stat3.jpg';
import imageFour from '../../../assets/img/common/stati/stat4.jpg';
import imageFive from '../../../assets/img/common/stati/stat5.jpg';
import imageSix from '../../../assets/img/common/stati/stat6.jpg';

import bgImage from '../../../assets/img/common/imgPageHeader.jpg';

const StatisticsComponent = () => (
   <div>
      <section className="inner-header" style={{backgroundImage: `url(${bgImage})`}}>
		<div className="container">
			<div className="row">
				<div className="col-md-12 sec-title colored text-center">
					<h2>About Us</h2>
					<ul className="breadcumb">
						<li><a href="index">Home</a></li>
						<li><i className="fa fa-angle-right"></i></li>
						<li><span>Statistics</span></li>
					</ul>
					<span className="decor"><span className="inner"></span></span>
				</div>
			</div>
		</div>
	</section>


	<section className="gallery-section pb_70">
		<div className="container">

			<div className="clearfix">
				<div className="row">
					<div className="col-md-12">
						<img className="img-responsive center-block" src="/img/common/stati/stat1.jpg" alt="statistics1"/>
					</div>
				</div>

				<div className="row">
					<div className="col-md-12">
						<img className="img-responsive center-block" src="/img/common/stati/stat2.jpg" alt="statistics2"/>
					</div>
				</div>

				<div className="row">
					<div className="col-md-12">
						<img className="img-responsive center-block" src="/img/common/stati/stat3.jpg" alt="statistics3"/>
					</div>
				</div>

				<div className="row">
					<div className="col-md-12">
						<img className="img-responsive center-block" src="/img/common/stati/stat4.jpg" alt="statistics4"/>
					</div>
				</div>

				<div className="row">
					<div className="col-md-12">
						<img className="img-responsive center-block" src="/img/common/stati/stat5.jpg" alt="statistics5"/>
					</div>
				</div>

				<div className="row">
					<div className="col-md-12">
						<img className="img-responsive center-block" src="/img/common/stati/stat6.jpg" alt="statistics6"/>
					</div>
				</div>
			</div>
		</div>
	</section>
   </div>
);

export default StatisticsComponent;