import React from 'react';

import imageOne from '../../../assets/img/common/inners/ITINERANT-TEACHERS.jpg';
import imageTwo from '../../../assets/img/common/inners/WATERSHEADMANAGEMENT.jpg';
import imageThree from '../../../assets/img/common/inners/SOCIAL-FORESTRY.jpg';
import imageFour from '../../../assets/img/common/inners/ANIMAL-HUSBANDRY.jpg';
import imageFive from '../../../assets/img/common/inners/HOUSING-PROJECTS.jpg';
import imageSix from '../../../assets/img/common/inners/HANDICRAFTS.jpg';
import imageSeven from '../../../assets/img/common/inners/BIO-GAS.jpg';
import imageEight from '../../../assets/img/common/inners/SELF-HELP-GROUPS.jpg';
import imageNine from '../../../assets/img/common/inners/INCOME-GENERATION-PROGRAMMES.jpg';
import imageEleven from '../../../assets/img/common/inners/OLD-AGE-HOMES.jpg';
import imageThirteen from '../../../assets/img/common/inners/HOUSING-FOR-THE-ELDERLY.jpg';
import imageFourteen from '../../../assets/img/common/inners/ANNUAL-SPORTS-AND-GAMES-FOR-THE-DISABLED.jpg';
import imageFifteen from '../../../assets/img/common/inners/CRICKET-TOURNAMENT-FOR-THE-VILLAGES.jpg';
import imageSixteen from '../../../assets/img/common/inners/RAS-GARBA-COMPETITION.jpg';

import bgImage from '../../../assets/img/common/imgPageHeader.jpg';



const OtherWelfareActivities = () => (
   <div>
      <section className="inner-header" style={{backgroundImage: `url(${bgImage})`}}>
         <div className="container">
            <div className="row">
               <div className="col-md-12 sec-title colored text-center">
                  <h2>Activities</h2>
                  <ul className="breadcumb">
                     <li><a href="index">Home</a></li>
                     <li><i className="fa fa-angle-right"></i></li>
                     <li><span>Other Welfare Activities</span></li>
                  </ul>
                  <span className="decor"><span className="inner"></span></span>
               </div>
            </div>
         </div>
      </section>


      <section className="event-feature sec-padding pb_60" data-bg-color="#fafafa">
         <div className="container">
            <div className="row">


               <div className="col-sm-12" >
                  <div className="event mb_30">
                     <div className="row">
                        <div className="col-sm-12">
                           <div className="event-content p_20 pl_0 pt_5">
                              <p className="mb_20" >
                                 In its efforts to reach the un-reached and less privileged sections of the society,
                                 Kutch Vikas Trust has got many other welfare activities also. We have reached more
                                 villages with Watershed management programmes, Women Empowerment programme, through
                                 self-help groups and handicrafts, housing for the poor, comprehensive eye care
                                 programmes, literary missions and we are also evolving strategies to create aids
												and HIV awareness programmes in the villages.</p>
                              <p className="mb_20">
                                 We also continue our eye care programme, with more awareness generation programmes,
                                 school screening, and more advanced and feasible eye care facilities. We also make
                                 the staff more competent with more exposure programmes and up-gradation and other
                                 training events in the field of health, education, village development, environmental
														development, co-operative enterprises and so on.</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>

      <section className="sec-padding faq-home faq-page">
         <div className="container">
            <div className="row">
               <div className="col-md-12">
                  <div className="accrodion-grp" data-grp-name="faq-accrodion">
                     <div className="accrodion active">
                        <div className="accrodion-title">
                           <h4>
                              <span className="decor">
                                 <span className="inner"></span>
                              </span>
                              <span className="text">VILLAGE DEVELOPMENT PROGRAMMES</span>
                           </h4>
                        </div>
                        <div className="accrodion-content">
                           <div className="event border-1px mb_30">
                              <div className="row">
                                 <div className="col-sm-5">
                                    <div className="event-thumb">
                                       <div className="thumb">
                                          <img className="full-width" src="/img/common/inners/ITINERANT-TEACHERS.jpg" alt="AGRICULTURE" />
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-sm-7">
                                    <div className="event-content p_20 pl_0 pt_5">
                                       <h3 className="event-title">AGRICULTURE</h3>
                                       <p className="mb_20">The most happening activity in Kutch is Agriculture. Most of the people are engaged
																in Agriculture. K. V. T. imparts agriculture training for a better harvest.</p>
                                    </div>
                                 </div>
                              </div>
                           </div>

                           <div className="event border-1px mb_30">
                              <div className="row">
                                 <div className="col-sm-5">
                                    <div className="event-thumb">
                                       <div className="thumb">
                                          <img className="full-width" src="/img/common/inners/WATERSHEADMANAGEMENT.jpg" alt="WATERSHED MANAGEMENT" />
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-sm-7">
                                    <div className="event-content p_20 pl_0 pt_5">
                                       <h3 className="event-title">WATERSHED MANAGEMENT</h3>
                                       <p className="mb_20">Arid and dry, as Kutch is always, watershed management is of high significance. We have
                                       undertaken watershed management in 21 villages, with, farm bunds, check dams, percolation pits,
																	well recharging and storage tanks.</p>
                                    </div>
                                 </div>
                              </div>
                           </div>

                           <div className="event border-1px mb_30">
                              <div className="row">
                                 <div className="col-sm-5">
                                    <div className="event-thumb">
                                       <div className="thumb">
                                          <img className="full-width" src="/img/common/inners/SOCIAL-FORESTRY.jpg" alt="SOCIAL FORESTRY" />
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-sm-7">
                                    <div className="event-content p_20 pl_0 pt_5">
                                       <h3 className="event-title">SOCIAL FORESTRY</h3>
                                       <p className="mb_20">Tree is life and it gives us a serene and pleasant climate.
                                       K. V. T. has undertaken massive forestation programme on a very large footing.
																			Planted more 5 lacs saplings and raised more that 9 lacs trees.</p>
                                    </div>
                                 </div>
                              </div>
                           </div>

                           <div className="event border-1px mb_30">
                              <div className="row">
                                 <div className="col-sm-5">
                                    <div className="event-thumb">
                                       <div className="thumb">
                                          <img className="full-width" src="/img/common/inners/ANIMAL-HUSBANDRY.jpg" alt="ANIMAL HUSBANDRY" />
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-sm-7">
                                    <div className="event-content p_20 pl_0 pt_5">
                                       <h3 className="event-title">ANIMAL HUSBANDRY</h3>
                                       <p className="mb_20">We have more than 300 animals, which provides us with milk and manure</p>
                                    </div>
                                 </div>
                              </div>
                           </div>

                           <div className="event border-1px mb_30">
                              <div className="row">
                                 <div className="col-sm-5">
                                    <div className="event-thumb">
                                       <div className="thumb">
                                          <img className="full-width" src="/img/common/inners/HOUSING-PROJECTS.jpg" alt="HOUSING PROJECTS" />
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-sm-7">
                                    <div className="event-content p_20 pl_0 pt_5">
                                       <h3 className="event-title">HOUSING PROJECTS</h3>
                                       <p className="mb_20">We have taken upon massive rehabilitation programme after the devastating earthquake
																				and built more than 2000 houses for the poor and disabled.</p>
                                    </div>
                                 </div>
                              </div>
                           </div>

                           <div className="event border-1px mb_30">
                              <div className="row">
                                 <div className="col-sm-5">
                                    <div className="event-thumb">
                                       <div className="thumb">
                                          <img className="full-width" src="/img/common/inners/BIO-GAS.jpg" alt="BIO GAS" />
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-sm-7">
                                    <div className="event-content p_20 pl_0 pt_5">
                                       <h3 className="event-title">BIO GAS</h3>
                                       <p className="mb_20">For a clean atmosphere and fuel efficiency, we have undertaken
																					promotion of biogas plants.</p>
                                    </div>
                                 </div>
                              </div>
                           </div>

                        </div>
                     </div>
                     <div className="accrodion ">
                        <div className="accrodion-title">
                           <h4>
                              <span className="decor">
                                 <span className="inner"></span>
                              </span>
                              <span className="text">WOMEN’S DEVELOPMENT & WELFARE</span>
                           </h4>
                        </div>
                        <div className="accrodion-content">
                           <div className="event border-1px mb_30">
                              <div className="row">
                                 <div className="col-sm-5">
                                    <div className="event-thumb">
                                       <div className="thumb">
                                          <img className="full-width" src="/img/common/inners/SELF-HELP-GROUPS.jpg" alt="SELF HELP GROUPS" />
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-sm-7">
                                    <div className="event-content p_20 pl_0 pt_5">
                                       <h3 className="event-title">SELF HELP GROUPS</h3>
                                       <p className="mb_20">We have over 45 groups spread over 6 villages, where more 400 women are
                                       members of the groups, who do some serious business. They have micro credit
																programmes and thrift and savings.</p>
                                    </div>
                                 </div>
                              </div>
                           </div>

                           <div className="event border-1px mb_30">
                              <div className="row">
                                 <div className="col-sm-5">
                                    <div className="event-thumb">
                                       <div className="thumb">
                                          <img className="full-width" src="/img/common/inners/INCOME-GENERATION-PROGRAMMES.jpg" alt="INCOME GENERATION PROGRAMMES" />
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-sm-7">
                                    <div className="event-content p_20 pl_0 pt_5">
                                       <h3 className="event-title">INCOME GENERATION PROGRAMMES</h3>
                                       <p className="mb_20">Many women are given loans from the banks, to start some sort of
                                       income generating activity, there by they are able to stand on their on, able
                                       to manage the finance of the family as a whole, which gives them recognition
																	and voice, in the society as a whole.</p>
                                    </div>
                                 </div>
                              </div>
                           </div>

                           <div className="event border-1px mb_30">
                              <div className="row">
                                 <div className="col-sm-5">
                                    <div className="event-thumb">
                                       <div className="thumb">
                                          <img className="full-width" src="/img/common/inners/HANDICRAFTS.jpg" alt="HANDICRAFTS" />
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-sm-7">
                                    <div className="event-content p_20 pl_0 pt_5">
                                       <h3 className="event-title">HANDICRAFTS</h3>
                                       <p className="mb_20">Kutch is very famous for various sorts of handicrafts. Women are encouraged through
                                       credit policies to take up handicrafts and our role is to help them market the product and
																		giving them new patterns models to make a living.</p>
                                    </div>
                                 </div>
                              </div>
                           </div>

                        </div>
                     </div>
                     <div className="accrodion ">
                        <div className="accrodion-title">
                           <h4>
                              <span className="decor">
                                 <span className="inner"></span>
                              </span>
                              <span className="text">GERIATRIC SERVICES FOR THE ELDERLY</span>
                           </h4>
                        </div>
                        <div className="accrodion-content">
                           <div className="event border-1px mb_30">
                              <div className="row">
                                 <div className="col-sm-5">
                                    <div className="event-thumb">
                                       <div className="thumb">
                                          <img className="full-width" src="/img/common/inners/OLD-AGE-HOMES.jpg" alt="OLD AGE HOMES" />
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-sm-7">
                                    <div className="event-content p_20 pl_0 pt_5">
                                       <h3 className="event-title">OLD AGE HOMES</h3>
                                       <p className="mb_20">We have two Old age Homes, where we take care of the Elderly and invalid senior citizens
                                       of the country. They are given boarding and lodging facilities and all the health care amenities.
																We have over 40 of them and have two sections, like, paying and free.</p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="event border-1px mb_30">
                              <div className="row">
                                 <div className="col-sm-5">
                                    <div className="event-thumb">
                                       <div className="thumb">
                                          <img className="full-width" src="/img/common/inners/SELF-HELP-GROUPS.jpg" alt="SELF HELP GROUP (S. H. G.)" />
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-sm-7">
                                    <div className="event-content p_20 pl_0 pt_5">
                                       <h3 className="event-title">SELF HELP GROUP (S. H. G.)</h3>
                                       <p className="mb_20">After the devastating Earthquake of 2001, we have taken the task of enabling the Elderly
                                       to live dignified life. With this aim in mind we have started the Self Help groups of the
																	elderly with support from agencies.</p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="event border-1px mb_30">
                              <div className="row">
                                 <div className="col-sm-5">
                                    <div className="event-thumb">
                                       <div className="thumb">
                                          <img className="full-width" src="/img/common/inners/INCOME-GENERATION-PROGRAMMES.jpg" alt="INCOME GENERATION PROGRAMMES" />
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-sm-7">
                                    <div className="event-content p_20 pl_0 pt_5">
                                       <h3 className="event-title">INCOME GENERATION PROGRAMMES</h3>
                                       <p className="mb_20">We also have given Income Generation Assistance to more 1000 elderly after the Earthquake to better
																		their lives. 4. Housing for the Elderly.</p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="event border-1px mb_30">
                              <div className="row">
                                 <div className="col-sm-5">
                                    <div className="event-thumb">
                                       <div className="thumb">
                                          <img className="full-width" src="/img/common/inners/HOUSING-FOR-THE-ELDERLY.jpg" alt="HOUSING FOR THE ELDERLY" />
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-sm-7">
                                    <div className="event-content p_20 pl_0 pt_5">
                                       <h3 className="event-title">HOUSING FOR THE ELDERLY</h3>
                                       <p className="mb_20">We built more than 2000 houses for the elderly, so that they can live a peaceful and comfortable life.</p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="accrodion ">
                        <div className="accrodion-title">
                           <h4>
                              <span className="decor">
                                 <span className="inner"></span>
                              </span>
                              <span className="text">ALLIED ACTIVITIES</span>
                           </h4>
                        </div>
                        <div className="accrodion-content">
                           <div className="event border-1px mb_30">
                              <div className="row">
                                 <div className="col-sm-5">
                                    <div className="event-thumb">
                                       <div className="thumb">
                                          <img className="full-width" src="/img/common/inners/ANNUAL-SPORTS-AND-GAMES-FOR-THE-DISABLED.jpg" alt="ANNUAL SPORTS AND GAMES FOR THE DISABLED" />
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-sm-7">
                                    <div className="event-content p_20 pl_0 pt_5">
                                       <h3 className="event-title">ANNUAL SPORTS AND GAMES FOR THE DISABLED</h3>
                                       <p className="mb_20">It is the annual event aimed at bringing all the disabled people together,
                                       to bring out the potential in them, to live like normal human beings. A two days programme
                                       is arranged, wherein sports, games and cultural competitions are held in different categories.
                                       All the winners are given prizes and boarding and lodging for the participants along
																with their escorts are arranged.</p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="event border-1px mb_30">
                              <div className="row">
                                 <div className="col-sm-5">
                                    <div className="event-thumb">
                                       <div className="thumb">
                                          <img className="full-width" src="/img/common/inners/CRICKET-TOURNAMENT-FOR-THE-VILLAGES.jpg" alt="CRICKET TOURNAMENT FOR THE VILLAGES" />
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-sm-7">
                                    <div className="event-content p_20 pl_0 pt_5">
                                       <h3 className="event-title">CRICKET TOURNAMENT FOR THE VILLAGES</h3>
                                       <p className="mb_20">A cricket tournament is arranged for the youth in the nearby villages. An ever rolling
                                       trophy is given and the winners are given certificates and citations. There are more than 40 teams
																	who take part actively in the competitions.</p>
                                    </div>
                                 </div>
                              </div>
                           </div>

                           <div className="event border-1px mb_30">
                              <div className="row">
                                 <div className="col-sm-5">
                                    <div className="event-thumb">
                                       <div className="thumb">
                                          <img className="full-width" src="/img/common/inners/RAS-GARBA-COMPETITION.jpg" alt="RAS GARBA COMPETITION" />
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-sm-7">
                                    <div className="event-content p_20 pl_0 pt_5">
                                       <h3 className="event-title">RAS GARBA COMPETITION</h3>
                                       <p className="mb_20">With an aim to spread traditional Ras Garba in the villages, we arrange competitions
                                       on the Sharad Purnima day with all the villages around taking part very actively. All the winners
																		are given attractive prizes and thus the message of celebration and traditionality is being kept up.</p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
   </div>
);

export default OtherWelfareActivities;