import React from 'react';

import imageOne from '../../../assets/img/common/inners/VISUALLY-IMPAIRED.jpg';
import imageTwo from '../../../assets/img/common/inners/HOSTEL-FOR-THE-DISABLED.jpg';
import imageThree from '../../../assets/img/common/inners/ADULT-EDUCATION.jpg';
import imageFour from '../../../assets/img/common/inners/ITINERANT-TEACHERS.jpg';

import bgImage from '../../../assets/img/common/imgPageHeader.jpg';

const EducationForDisabled = () => (
   <div>
      <section className="inner-header" style={{backgroundImage: `url(${bgImage})`}}>
		<div className="container">
			<div className="row">
				<div className="col-md-12 sec-title colored text-center">
					<h2>Activities</h2>
					<ul className="breadcumb">
						<li><a href="index">Home</a></li>
						<li><i className="fa fa-angle-right"></i></li>
						<li><span>Education for the Disabled</span></li>
					</ul>
					<span className="decor"><span className="inner"></span></span>
				</div>
			</div>
		</div>
	</section>


	<section className="event-feature sec-padding pb_60" data-bg-color="#fafafa">
		<div className="container">
			<div className="row">
				<div className="col-sm-12">
					<div className="event border-1px mb_30">
						<div className="row">
							<div className="col-sm-5">
								<div className="event-thumb">
									<div className="thumb">
										<img className="full-width" src="/img/common/inners/VISUALLY-IMPAIRED.jpg" alt="INTEGRATED EDUCATION CENTRE FOR THE VISUALLY IMPAIRED & PHYSICALLY HANDICAPPED"/>
									</div>
								</div>
							</div>
							<div className="col-sm-7">
								<div className="event-content p_20 pl_0 pt_5">
									<h3 className="event-title">INTEGRATED EDUCATION CENTRE FOR THE VISUALLY IMPAIRED & PHYSICALLY HANDICAPPED</h3>
									<p className="mb_20">The Main Concern of Kutch Vikas Trust is the education of the blind and handicapped
										children, the less privileged in the society and working for their all-round development.
										They are given free education and training in the school. In this Integrated Education
										Centre the physically and visually disabled study along with the normal children coming
										from the slum area and nearby villages.</p>
									<p className="mb_20">Here they have an opportunity to compete with the normal children and at the
										end of their curricular and extracurricular programme; they would be able to live almost
										a normal life.</p>
									<p className="mb_20">The visually challenged are given special training to cope up with the
										difficulties in their life from the early days onwards. Special coaching is imparted
										in Braille and daily living skills. With special facilities, a resource room is
										provided to impart this training to the blind children. The medium of instruction
										of the school is English and under the aegis of efficient and dedicated staff,
										the school is making great strides. They also have the opportunity to participate
										in extracurricular and co-curricular activities of the school.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-sm-12">
					<div className="event border-1px mb_30">
						<div className="row">
							<div className="col-sm-5">
								<div className="event-thumb">
									<div className="thumb">
										<img className="full-width" src="/img/common/inners/HOSTEL-FOR-THE-DISABLED.jpg" alt="HOSTEL FOR THE DISABLED"/>
									</div>
								</div>
							</div>
							<div className="col-sm-7">
								<div className="event-content p_20 pl_0 pt_5">
									<h3 className="event-title">HOSTEL FOR THE DISABLED</h3>
									<p className="mb_20">With an aim to impart free and quality education to Disabled children, who are otherwise
										unable to attend school due to their disability and poverty are given free accommodation, food,
										and clothing in the hostel. Both girls and boys, physically, visually and multi-disabled, are
										given accommodation in the hostel.</p>
									<p className="mb_20">The hostel is divided into twelve units or houses wherein a housemother looks
										after each hostel, who cares for 12-15 children; who are physically and visually challenged.
										A meticulous timetable with extra inputs and coaching instill a sense of confidence and pride
										in the children to face the challenges of the world outside.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-sm-12">
					<div className="event border-1px mb_30">
						<div className="row">
							<div className="col-sm-5">
								<div className="event-thumb">
									<div className="thumb">
										<img className="full-width" src="/img/common/inners/ADULT-EDUCATION.jpg" alt="ADULT EDUCATION"/>
									</div>
								</div>
							</div>
							<div className="col-sm-7">
								<div className="event-content p_20 pl_0 pt_5">
									<h3 className="event-title">ADULT EDUCATION</h3>
									<p className="mb_20">We find 0% literacy in some of the villages in Kutch. With an aim to enable the
										illiterate to make a sense of their life, we impart adult the opportunity, at least to scribble
										their names and to create a sense of satisfaction and pride in their lives.</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="col-sm-12">
					<div className="event border-1px mb_30">
						<div className="row">
							<div className="col-sm-5">
								<div className="event-thumb">
									<div className="thumb">
										<img className="full-width" src="/img/common/inners/ITINERANT-TEACHERS.jpg" alt="ITINERANT TEACHERS"/>
									</div>
								</div>
							</div>
							<div className="col-sm-7">
								<div className="event-content p_20 pl_0 pt_5">
									<h3 className="event-title">ITINERANT TEACHERS</h3>
									<p className="mb_20">In order to impart basic education at the doorsteps of the visually challenged,
										K. V. T. provides teachers for their education. These teachers go from village to village
										and train the blind for Braille writing, Orientation, and Mobility, Daily Living Skills, etc.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	</section>
   </div>
);

export default EducationForDisabled;