import React from 'react';

const FooterLatestNews = () => (
    <ul>
        <li>
            {/*<span className="border"></span>
            <div className="content">
                <a href="blog-details.html">If you need a crown or lorem an implant you will pay it </a>
                <span>July 2, 2014</span>
            </div>*/}
        </li>
        <li>
            {/*<span className="border"></span>
            <div className="content">
                <a href="blog-details.html">If you need a crown or lorem an implant you will pay it </a>
                <span>July 2, 2014</span>
            </div>*/}
        </li>
        <li>
            {/*<span className="border"></span>
            <div className="content">
                <a href="blog-details.html">If you need a crown or lorem an implant you will pay it </a>
                <span>July 2, 2014</span>
            </div>*/}
        </li>
   </ul>
);

export default FooterLatestNews;