import React from 'react';

const TopBar = () => (
    <section className="top-bar">
        <div className="container">
            <div className="left-text pull-left">
                <p><span>Support Us :</span> info@kutchvikastrust.org</p>
            </div>
            <div className="social-icons pull-right">
                <ul>
                    <li><a href="https://www.facebook.com/KutchVikasTrust/" target="_blank"><i className="fa fa-facebook"></i></a></li>
                    <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                    <li><a href="#"><i className="fa fa-instagram"></i></a></li>
                </ul>
            </div>
        </div>
    </section>
);

export default TopBar;