import React from 'react';
import { Link } from 'react-router-dom';

import HeaderNavComponent from './headernav.component';
import TopBar from './topbar.component';

const HeaderComponent = () => {

    return (
        <div>
            <TopBar />
            <header className="header">
                <div className="container">
                    <div className="logo pull-left">
                        <Link to="/">
                            <img src="img/common/logo.png" alt="Kutch Vikas Trust Charity" />
                        </Link>
                    </div>
                    <div className="header-right-info pull-right clearfix">
                        <div className="single-header-info">
                            <div className="icon-box">
                                <div className="inner-box">
                                    <i className="flaticon-interface-2"></i>
                                </div>
                            </div>
                            <div className="content">
                                <h3>EMAIL</h3>
                                <p>info@kutchvikastrust.org</p>
                            </div>
                        </div>
                        <div className="single-header-info">
                            <div className="icon-box">
                                <div className="inner-box">
                                    <i className="flaticon-telephone"></i>
                                </div>
                            </div>
                            <div className="content">
                                <h3>Call Now</h3>
                                <p><b>+91 . 2832 . 274229</b></p>
                            </div>
                        </div>
                        <div className="single-header-info">
                            <Link className="thm-btn" to="/an-appeal">Donate Now</Link>
                        </div>
                    </div>
                </div>
            </header>
            <HeaderNavComponent />
        </div>)
};

export default HeaderComponent;