import React from 'react';

import imageOne from '../../../assets/img/common/inners/imgFrGeorgeKunnath.jpg';

import bgImage from '../../../assets/img/common/imgPageHeader.jpg';

const FrGeorge = () => (
   <div>


      	<section className="inner-header" style={{backgroundImage: `url(${bgImage})`}}>
		<div className="container">
			<div className="row">
				<div className="col-md-12 sec-title colored text-center">
					<h2>About Us</h2>
					<ul className="breadcumb">
						<li><a href="index.html">Home</a></li>
						<li><i className="fa fa-angle-right"></i></li>
						<li><span>About Us</span></li>
					</ul>
					<span className="decor"><span className="inner"></span></span>
				</div>
			</div>
		</div>
	</section>

   <section className="sec-padding about-content full-sec">
		<div className="container">
			<div className="row">

				<div className="col-md-6">
					<div className="full-sec-content">
						<div className="sec-title style-two">
							<h2 >Rev. Fr. George Kunnath CMI (1940-2012)</h2>
							<span className="decor" >
								<span className="inner"></span>
							</span>
						</div>
						<h4 >The brain behind the dream<br/> project was Fr. George Kunnath</h4>
						<br/>
						<p >“Savaya Kutchi”, (a person exalted more than a Kutchi) Fr. George Kunnath,
							our mentor,
							guide, inspiration and the unmatched icon of service for the poor, marginalized and
							downtrodden, has left us for his heavenly reward. It was his love for the sick and
							invalid that prompted him to travel to Bangalore to meet his ailing Old Friend and
							Guide Rev. Fr. Robert Cutinah S.J. In spite of his ill-health and fatigue, he decided
							to take up the journey and on the way in Mumbai on 27th October 2012; he had a Massive
							Cerebral Hemorrhage and was admitted in Lilavaty Hospital Mumbai. There, despite the
							best efforts of the Medical Fraternity, he left this world on 31st October 2012 at 8.00am.
						</p>
						<br/>

					</div>
				</div>
				<div className="col-md-6 hidden-md text-right" >
					<img src="/img/common/inners/imgFrGeorgeKunnath.jpg" alt="Fr. George Kunnath" />
				</div>
			</div>
			<div className="row">

				<div className="col-sm-12">
					<div className="full-sec-content">
						<br/>
						<p >
							Born on March 12, 1940, at Chelav, near Karimannoor in Idukki District of Kerala as the
							second child of 12 children to Late Mr. John and Mary Kunnath, Fr. George joined the CMI
							congregation at the age of 16 and made his First Profession on 26/05/1961 and was ordained
							on 21/04/1969. After his ordination, he was appointed the manager of Dharmaram farms, and
							after 9 years of innovative and dedicated services there he took up the role of Prior of
							Vazhakkulam monastery. Here too he accomplished creative and illustrative enterprises,
							including Jyoti Super Bazaar in Thodupuzha. Upon the request of late Rev. Bishop Jonas
							Thaliath he came over to Rajkot in 1981 to realize the dream projects of the prelate.
						</p>
						<br/>
						<p >
							The brain behind the dream project was Fr. George Kunnath who accelerated the growth of
							Catholic Presence in the district of Kutch. Registered as a Non-Governmental Organization,
							Kutch Vikas Trust branched out to different areas of operation within a short period of
							time,
							catering and caring for the poor, the marginalized, the downtrodden, the disabled and the
							uncared for.
						</p>
						<br/>
						<p >
							He walked in the footsteps of our Founding Father Blessed Kuriakose and was always concerned
							about
							the growth and health of the people in this parched land of sand and storms. He developed an
							oasis
							in the desert that surprised very many people. He was conferred with many national and state
							awards. He was a great missionary who conveyed the message of the love of God to the poor
							and
							suffering people on the hard terrains of Kutch. The visually, physically and mentally
							challenged,
							the hearing and multi-sensory impaired- all found new energy and life in him. He was
							considered
							by the locals the uncrowned king of Kutch because of the amount of welfare work he did and
							the
							influence he had in the society and with the Government.
						</p>
						<br/>
						<p >
							He was convinced of the enormous need for education that would bring lot many positive
							changes
							in the lives of the people. He went around the villages and towns; he met the local leaders
							and community members, and with their backing and support he opened various educational
							institutions in different parts of the district. His ardent love for the disabled and the
							poor enthused him to start integrated schools in all the major towns of the district. He
							wanted the less privileged to compete with the elite. He envisioned that without education,
							there will be no social transformation and empowerment. He preached Christ through his
							actions and accomplishments.
						</p>
						<br/>
						<p >
							He wanted to reach out to the poor with his health care and developmental initiatives.
							He established eye care facilities, he built homes for the poor, he sheltered the HIV/AIDS
							infected, he executed watershed programmes for village development, he started
							cooperatives, he conducted medical camps for the poor, he went in search of the
							disabled in the villages, and he conducted programmes for the farmers and peasants.
							He left no village in Kutch untouched. He did not wait for perfect times, he perfected
							the times. His motto was “God Will Provide”.
						</p>
						<br/>
						<p >
							The various developmental initiatives that he began and the multitude of centers
							that he established in Kutch all stand witness to his enormous vision and magnanimity.
							He was hard working, a visionary, a good economist, a good religious and above all a
							good human being with a high sense of humor. He had great ideas and greater courage;
							the disabled and the elderly were his only weakness. He started KVT a quarter of the
							century ago from zero. Today KVT forms a well-established organization with well-laid
							infrastructure, well-planned strategies, and well-trained staff.
						</p>
						<br/>
						<p >
							The Kutch Vikas Trust which reinvented the rules of business to restore sight to the
							blind and new life too differently abled children is an institution marked by elegance
							and clarity. It suggests that choices that seem naive or unworkable can when executed
							with wisdom and integrity, yield bafflingly extraordinary results.
						</p>
						<br/>
						<p >
							In 1981 he came to Bhuj in Kutch District of Gujarat. He conceived a project he
							believed in. He opened an institution for the care of blind children in a small
							rented home with 5 children. He had no financial sources but had a special way of
							seeing, a vision perfectly aligned. Starting a house for the blind and physically
							handicapped in arid Kutch was a revolutionary, spiritual act, a manifestation of
							Christ’s compassionate love for the unfortunate people.
						</p>
						<br/>
						<p >
							Fr. George was a prudent priest preferring to rely on the life of Jesus who went
							about doing good. He had a vision, larger than other entrepreneurs in the field.
							His mission was to eliminate curable blindness and other disabilities and to
							rehabilitate the poor people in the villages of Kutch. With this mission in mind,
							he toured all the 964 villages in Kutch District and conducted medical camps in
							each village. His philosophy was “To see all as one. To give sight to all”.
							The radical essence Fr. George’s strategy was to provide free eye care to those
							who cannot afford to pay. He started eye hospital and K.V.T Hostels. Thousands
							were treated and thousands were given shelter in the hostels and free integrated
							education was given to the children.
						</p>
						<br/>
						<p >
							Defying conventional business logic K.V.T is the largest provider of the eye – care
							in Kutch District, perhaps in the whole of Gujarat. “The greatest business case for
							compassion-has treated over lakhs of patients in 31 years majority for free or marginal
							payment. Today K.V.T is self – sustaining and highly appreciated.
						</p>
						<br/>
						<p >
							Fr. George’s dramatic decision was based on the inexpressible logic of his spiritually
							guided awareness. But his critics realized that his institution yielded tremendous
							benefits for the unfortunate differently abled children. Initially, he staffed the
							organization with low profile workers. Today a team of 300 strong workers are
							rendering care and in three places to nearly 200 children in K.V.T.
						</p>
						<br/>
						<p >
							The K.V.T eye care-system comprises two eye hospitals in KVT and Naliya, hostels
							in K.V.T and other hostels in Nakathrana, Naliya and Bachau. The depth of
							insight elevates the narration beyond enumeration of the success of a care enterprise.
						</p>
						<br/>
						<p >
							This is a true story of one man’s pragmatism and belief that healed thousands.
							Charged with profound insights it reveals the radical principles behind Fr.
							George’s baffling success. This explains the mystery of a model that integrates
							innovation with empathy, service with business principles and inner change with
							outer transformation.
						</p>
						<br/>
						<p >
							The institutions he started reveal Fr. George’s indefatigable spirit and beliefs of
							a man whose life was guided by Gospel ideals, by divinity, compassion, and spirituality
							which focused on clear goals. Thoughtful strategies and sound management practices
							produced results. There is the continuous sense of wonderment and administration
							that leaves all hopeful about trying to make difference in every aspect of the
							running of the K.V.T from the provider and recipient. What shines is the simple
							principle that compassion and humility bring satisfaction in the institution as
							a whole. The obvious commitment of the entire staff has been captured brilliantly
							and stresses its crucial importance in the project of this scale. The dedication
							and belief are palpable and I was astonished at the volume of patients handled,
							the facilities and surgical skills available and absolute dedication to the workforce.
							The extent of employment provided to the local population is a wonder in itself. K.V.T
							educates people from neighboring villages and most support staff is locally procured.
							This feature, which may never be duplicated, makes K.V.T Gujarat’s largest socially
							relevant service provider.
						</p>
						<br/>
						<p >
							The K.V.T which leaves you wanting to contribute is about leadership, service,
							institution building and the power of a model that contributes business discipline
							with compassion. K.V.T reaffirms our admiration for the work that Fr. George did
							for the society for the last three decades.
						</p>
						<br/>
					</div>
				</div>
			</div>
			<div className="row">

				<div className="col-md-12" >
					<div className="full-sec-content"><br/>
						<h4>Major Institutions and Activities of K.V.T started by Fr. George</h4>
					</div>
				</div>

				<div className="col-sm-12">
					<div className="full-sec-content">
						<br/>
						<p>
						<ul>
							<li >Free Hostels for the blind and differently abled children</li>
							<li >Eye Hospital</li>
							<li >School for Integrated Education</li>
							<li >Hostel for the Elderly</li>
							<li >Eye care Activities in different centers</li>
							<li >Education and care of the Disabled</li>
							<li >Centre for the Deaf, Dumb, Blind and multi-disabled</li>
							<li >Centre for Mental Health Development</li>
							<li >Refractive Error Services</li>
							<li >Community-Based Rehabilitation Programmes</li>
							<li >Village Development Programmes</li>
							<li >Women’s Development and Welfare</li>
							<li >Liturgy Programme</li>
							<li >Housing Project</li>
							<li >Dairy and Hatchery</li>
							<li >Cultivation and forestation</li>
							<li >Community Care Centre for the HIV/AIDS affected</li>
						</ul>
						</p>
						<br/>
					</div>
				</div>
			</div>

			<div className="row">

				<div className="col-md-12" >
					<div className="full-sec-content"><br/>
						<h4>Major Awards and Recognitions</h4>
					</div>
				</div>

				<div className="col-sm-12">
					<div className="full-sec-content">
						<br/>
						<p>
						<ul>
							<li >1998: Van Pandit Award by Government of Gujarat.</li>
							<li >2001: Award for outstanding services to the disabled from the
								Government of Gujarat.</li>
							<li >2002: Award for remarkable service to the earthquake affected people.
							</li>
							<li >2003: Bharat Excellence Award for outstanding services for the
								marginalized and the poor.</li>
							<li >2004: Best Employer of the Disabled by the Government of Gujarat.
							</li>
							<li >2006: National Award for Best Employer of the Disabled.</li>
							<li >2007: Award for outstanding services for the welfare and Development
								of the disabled in Kutch, by the Kutch Blind Welfare Association.</li>
							<li >2008: Social Apostolate Award by CMI Congregation for outstanding
								services for the needy and the disabled.</li>
							<li >2009: State Award for the welfare of Blind and Disabled, Gujarat
								State Branch of NAB.</li>
						</ul>
						</p>
						<br/>
						<p >Fr. George is a man of dynamism and initiative. He had the courage to take
							calculated risks. He
							succeeded in all the fields he touched. He initiated the efforts of afforestation with a
							rare
							farsightedness, dedication, cooperation and participation of the people; as result, today,
							not only K.V.T area but the surrounding villages also have more trees and plants. The three
							decades of service for the disabled had been widely recognized and appreciated by the
							people,
							both the state and central governments.</p>
						<br/>
						<p >Today the K.V.T is the beacon of hope for thousands of visually impaired
							and
							physically challenged people in Kutch district spread over 46000 sq km, a district bigger
							than
							the state of Kerala. Fr. George Kunnath with his pioneering spirit, dynamism, and love for
							the
							handicapped worked over three decades. And as a result, the K.V.T is a symbol of Christ’s
							compassionate love for the less privileged people of our motherland.</p>
						<br/>
					</div>
				</div>
			</div>

			<div className="row">

				<div className="col-md-12" >
					<div className="full-sec-content"><br/>
						<h4>BioData of Fr. George Kunnath CMI</h4>
					</div>
				</div>

				<div className="col-sm-12">
					<div className="full-sec-content">
						<br/>
						<p >Name: <strong>Fr. George Kunnath CMI</strong></p>
						<p >Father’s Name: <strong>Mr. John</strong></p>
						<p >Mother’s Name: <strong>Mrs. Mariam</strong></p>
						<p >House Name: <strong>Kunnath</strong></p>
						<p >Date of Birth: <strong>12/03/1940</strong></p>
						<p >Place of Birth: <strong>Karimannor</strong></p>
						<br/>
					</div>
				</div>
			</div>
			<div className="row">

				<div className="col-md-12" >
					<div className="full-sec-content"><br/>
						<h4>Appointments</h4>
					</div>
				</div>

				<div className="col-sm-12">
					<div className="full-sec-content">
						<br/>
						<p >Director of Dharmaram Farms: 1970-1978</p>
						<p >Prior, Monastery, Vazhakkulam: 1978-1981</p>
						<p >Parish Priest, St. Mary’s Church Bhuj: 1981-1984</p>
						<p >Director, Kutch Vikas Trust: 1981-</p>
						<br/>
					</div>
				</div>
			</div>
			<div className="row">

				<div className="col-md-12" >
					<div className="full-sec-content"><br/>
						<h4>Accomplished Works in Kutch Vikas Trust</h4>
					</div>
				</div>

				<div className="col-sm-12">
					<div className="full-sec-content">
						<br/>
						<p >KVT School at Bhuj – 1984</p>
						<p >KVT Integrated School at Raidhapar – 1986</p>
						<p >KVT Hostels – 1986</p>
						<p >KVT Eye Hospital – 1987</p>
						<p >KVT Old Age home – 1999</p>
						<p >KVT center for the deafblind – 2000</p>
						<p >KVT center for MR – 2006</p>
						<p >KVT center for HI – 2008</p>
						<p >KVT CCC for HIV/AIDS – 2010</p>
						<p >Integrated School at Bhachau – 1993</p>
						<p >Integrated School at Nakhatrana – 1994</p>
						<p >Integrated School at Naliya – 1995</p>
						<p >English Medium School at Dhaneti – 2006</p>
						<p >Western India Fish Hatcheries – 1990</p>
						<br/>
					</div>
				</div>
			</div>
			<div className="row">

				<div className="col-md-12" >
					<div className="full-sec-content"><br/>
						<h4>Inspiration Behind</h4>
					</div>
				</div>

				<div className="col-sm-12">
					<div className="full-sec-content">
						<br/>
						<p >Centre at Lakhpat</p>
						<p >Centre at Dayapar</p>
						<p >Centre at Mandvi</p>
						<p >Centre at Mankuva</p>
						<p >Centre at Lodai</p>
						<p >Centre at Nadapa</p>
						<p >Center at Mundra</p>
						<br/>
					</div>
				</div>
			</div>
			<div className="row">

				<div className="col-md-12" >
					<div className="full-sec-content"><br/>
						<h4>Activities Involved in</h4>
					</div>
				</div>

				<div className="col-sm-12">
					<div className="full-sec-content">
						<br/>
						<p >National Association for the Blind, Kutch</p>
						<p >Blind Welfare Council, Kutch</p>
						<p >Federation of Blind, Kutch</p>
						<p >Diocesan Advisory Council</p>
						<p >Provincial Advisory Council</p>
						<br/>
					</div>
				</div>
			</div>
			<div className="row">

				<div className="col-md-12" >
					<div className="full-sec-content"><br/>
						<h4>Govt. Bodies such as</h4>
					</div>
				</div>

				<div className="col-sm-12">
					<div className="full-sec-content">
						<br/>
						<p >RCH committee of GOG</p>
						<p >Committee for Child Labour Abolition</p>
						<p >Lead NGO, Hariyali Project</p>
						<p >Lead NGO, Watershed Management</p>
						<p >Fisher people’s association</p>
						<br/>
					</div>
				</div>
			</div>
		</div>
	</section>

   </div>
);

export default FrGeorge;