import React from 'react';

const NewsComponent = () => (
	<section className="gallery-section pb_70">
		<div className="container">
        <div className="event style-two">
          <div className="row">
            <div className="col-xs-2">
              <div className="event-thumb">
                <ul className="event-date">
                  <li className="date">09</li>
                  <li className="month">Dec</li>
                </ul>
              </div>
            </div>
            <div className="col-xs-10">
              <div className="event-content">
                <h5 className="event-title">31<sup>st</sup>All Kutch Meet for the Differently Abled  </h5>
                <ul className="event-held list-inline">
                  <li className="mr-10" data-text-color="#555"><i className="fa fa-clock-o"></i> 12.00 pm</li>
                </ul>
                <p className="mb-0">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut 
                labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. 
                Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, c
                onsetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed 
                diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata 
                sanctus est Lorem ipsum dolor sit amet</p>
              </div>
            </div>
          </div>
        </div>
        <div className="event style-two">
          <div className="row">
            <div className="col-xs-2">
              <div className="event-thumb">
                <ul className="event-date">
                  <li className="date">09</li>
                  <li className="month">Dec</li>
                </ul>
              </div>
            </div>
            <div className="col-xs-10">
              <div className="event-content">
                <h5 className="event-title">31<sup>st</sup>All Kutch Meet for the Differently Abled</h5>
                <ul className="event-held list-inline">
                  <li className="mr-10" data-text-color="#555"><i className="fa fa-clock-o"></i> 12.00 pm</li>
                </ul>
                <p className="mb-0">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut 
                labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. 
                Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, c
                onsetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed 
                diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata 
                sanctus est Lorem ipsum dolor sit amet</p>
              </div>
            </div>
          </div>
        </div>
        <div className="event style-two">
          <div className="row">
            <div className="col-xs-2">
              <div className="event-thumb">
                <ul className="event-date">
                  <li className="date">09</li>
                  <li className="month">Dec</li>
                </ul>
              </div>
            </div>
            <div className="col-xs-10">
              <div className="event-content">
                <h5 className="event-title">  31<sup>st</sup>All Kutch Meet for the Differently Abled  </h5>
                <ul className="event-held list-inline">
                  <li className="mr-10" data-text-color="#555"><i className="fa fa-clock-o"></i> 12.00 pm</li>
                </ul>
                <p className="mb-0">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut 
                labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. 
                Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, c
                onsetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed 
                diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata 
                sanctus est Lorem ipsum dolor sit amet</p>
              </div>
            </div>
          </div>
        </div>
        <div className="event style-two">
          <div className="row">
            <div className="col-xs-2">
              <div className="event-thumb">
                <ul className="event-date">
                  <li className="date">09</li>
                  <li className="month">Dec</li>
                </ul>
              </div>
            </div>
            <div className="col-xs-10">
              <div className="event-content">
                <h5 className="event-title">  31<sup>st</sup>All Kutch Meet for the Differently Abled  </h5>
                <ul className="event-held list-inline">
                  <li className="mr-10" data-text-color="#555"><i className="fa fa-clock-o"></i> 12.00 pm</li>
                </ul>
                <p className="mb-0">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut 
                labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. 
                Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, c
                onsetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed 
                diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata 
                sanctus est Lorem ipsum dolor sit amet</p>
              </div>
            </div>
          </div>
        </div>
        <div className="event style-two">
          <div className="row">
            <div className="col-xs-2">
              <div className="event-thumb">
                <ul className="event-date">
                  <li className="date">09</li>
                  <li className="month">Dec</li>
                </ul>
              </div>
            </div>
            <div className="col-xs-10">
              <div className="event-content">
                <h5 className="event-title">  31<sup>st</sup>All Kutch Meet for the Differently Abled  </h5>
                <ul className="event-held list-inline">
                  <li className="mr-10" data-text-color="#555"><i className="fa fa-clock-o"></i> 12.00 pm</li>
                </ul>
                <p className="mb-0">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut 
                labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. 
                Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, c
                onsetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed 
                diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata 
                sanctus est Lorem ipsum dolor sit amet</p>
              </div>
            </div>
          </div>
        </div>
        <div className="event style-two">
          <div className="row">
            <div className="col-xs-2">
              <div className="event-thumb">
                <ul className="event-date">
                  <li className="date">09</li>
                  <li className="month">Dec</li>
                </ul>
              </div>
            </div>
            <div className="col-xs-10">
              <div className="event-content">
                <h5 className="event-title">  31<sup>st</sup>All Kutch Meet for the Differently Abled  </h5>
                <ul className="event-held list-inline">
                  <li className="mr-10" data-text-color="#555"><i className="fa fa-clock-o"></i> 12.00 pm</li>
                </ul>
                <p className="mb-0">31<sup>st</sup> All Kutch Meet for the Differently Abled at 12.00 pm on Sunday 9th December 2018.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="event style-two">
          <div className="row">
            <div className="col-xs-2">
              <div className="event-thumb">
                <ul className="event-date">
                  <li className="date">09</li>
                  <li className="month">Dec</li>
                </ul>
              </div>
            </div>
            <div className="col-xs-10">
              <div className="event-content">
                <h5 className="event-title">  31<sup>st</sup>All Kutch Meet for the Differently Abled  </h5>
                <ul className="event-held list-inline">
                  <li className="mr-10" data-text-color="#555"><i className="fa fa-clock-o"></i> 12.00 pm</li>
                </ul>
                <p className="mb-0">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut 
                labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. 
                Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, c
                onsetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed 
                diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata 
                sanctus est Lorem ipsum dolor sit amet</p>
              </div>
            </div>
          </div>
        </div>
      </div>
   </section>
);

export default NewsComponent;