import React from 'react';

const FooterBottom = () => (
	<section class="footer-bottom">
		<div class="container text-center">
			<p>This website is created with Love...</p>
		</div>
	</section>
);

export default FooterBottom;