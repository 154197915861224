import React from 'react';
import imageOne from '../../../assets/img/common/inners/aboutImage1.jpg';

import bgImage from '../../../assets/img/common/imgPageHeader.jpg';

const KutchVikasTrust = () => (
   <div>
	<section className="inner-header" style={{backgroundImage: `url(${bgImage})`}}>
		<div className="container">
			<div className="row">
				<div className="col-md-12 sec-title colored text-center">
					<h2>About Us</h2>
					<ul className="breadcumb">
						<li><a href="index">Home</a></li>
						<li><i className="fa fa-angle-right"></i></li>
						<li><span>About Us</span></li>
					</ul>
					<span className="decor"><span className="inner"></span></span>
				</div>
			</div>
		</div>
	</section>


	<section className="sec-padding about-content full-sec">
		<div className="container">
			<div className="row">
				<div className="col-md-12">
					<div className="full-sec-content">
						<div className="sec-title style-two">
							<h2>About Kutch Vikas Trust</h2>
							<span className="decor">
								<span className="inner"></span>
							</span>
						</div>
						<h3>The main activity of Kutch Vikas Trust is welfare activities for the disabled.</h3>
					</div>
				</div>
			</div>
			<div className="row">

				<div className="col-lg-6 col-md-6 col-sm-12">
					<div className="full-sec-content">
						<br/>
						<p>Kutch Vikas Trust is a charitable organization registered with the government. It is registered under
							Bombay Public Trust Act, 1950 Reg. No. E644/Dt. 27-11-1980. This is non-political, non-profit and
							non-sectarian in nature. The services of Kutch Vikas Trust are available to all sections of the
							people, without any discrimination of caste, religion and social status.The main activity of Kutch Vikas Trust
							is welfare activities for the disabled. We
							started these activities 20 years ago. </p>
						<br/>
					</div>
				</div>
				<div className="col-lg-6 col-md-6 col-sm-12">
					<div className="full-sec-content">
						<br/>
						<p>This includes Formal Education of the Disabled
							Children in integrated setup, Vocational Training, Rehabilitation Services, Placement
							and Employment Services, Marriage Bureaus for the Disabled, Prevention of Disability,
							Comprehensive Services to the disabled etc. Majority out of the 300 staff members in
							Kutch Vikas Trust are engaged in Welfare Activities of the Disabled.
						</p>
						<br/>
					</div>
				</div>

			</div>

			<div className="row">
				<div className="col-md-12">
					<div className="full-sec-content">
						<div className="sec-title style-two">
							<h2>History of Kutch Vikas Trust</h2>
							<span className="decor">
								<span className="inner"></span>
							</span>
						</div>
						<h3>Late Bishop Jonas Thaliath of Rajkot sowed the seeds of this unique project, which now stands out as an oasis
							in the dry barren Kutch.</h3>
					</div>
				</div>
			</div>

			<div className="row">

				<div className="col-md-12">
					<div className="full-sec-content"><br/>
						<br/>
						<h4>Demography, Culture, and Religious Context:</h4>
					</div>
				</div>

				<div className="col-sm-12">
					<div className="full-sec-content">
						<br/>
						<p>Kutch is the name of a district in Gujarat state. This is the largest district in this state as well as
							in the whole of India. This is also one of the most backward districts of this country. The major part
							of this district is covered by desert called “Rann of Kutch”. Even though the district is the biggest
							in India, the density of population is very low. The present population in the district is 1.6 million
							(the Year 2006). Hindu and Islam are the major religions in this area. Caste discrimination is high.
							The literacy rate is very low. Kutchi is the commonly using local language. Scanty rain and the
							shortage of water caused thousands of people’s migration to other parts of the state. </p>
						<br/>
					</div>
				</div>
			</div>

			<div className="row">

				<div className="col-md-12">
					<div className="full-sec-content"><br/>
						<h4>Reason for Establishing the Institution :</h4>
					</div>
				</div>

				<div className="col-sm-12">
					<div className="full-sec-content">
						<br/>
						<p>Late Bishop Jonas Thaliath of Rajkot sowed the seeds of this unique project,
							which now stands out as an oasis in the dry barren Kutch. He had the integral
							development of the people of Kutch in his mind when a 250 acres barren plot was
							purchased in 1980. His successor Bishop Gregory Karotemprel had shared the same
							vision and entrusted Fr. George Kunnath with the task of giving concrete shape to
							this dream. Fr. Kunnath had seen for himself the unique problems of the sparsely
							populated Kutch region with less than 25 people per sq. Km. far below the national
							average of 267 people per sq. km. The unusual sight of too many blind and
							polio-affected people had arrested his attention.
						</p>
						<br/>
					</div>
				</div>
			</div>

			<div className="row">
				<div className="col-md-12">
					<img className="img-responsive" src="/img/common/inners/aboutImage1.jpg" alt="Kutch Vikas Trust"/>
				</div>
			</div>

			<div className="row">

				<div className="col-sm-12">
					<div className="full-sec-content">
						<br/>
						<p>Fr. George Kunnath started informal education for 5 blind children in 1984 in a rented building in Bhuj.
							And green saplings were planted in the KVT campus at Raidhanpar Village. In the meanwhile, a door to
							door preliminary survey was conducted by KVT in all the 966 villages of Kutch District.
						</p>
						<br/>
						<p>Foundation stone of Kutch Vikas Trust buildings was laid on 24-05-1985 by Dr. Mary Rajaram in the presence
							of Shri G.L. Bhagat (District Collector), Mr. Rajaram, Kundanbhai Dholakia, Dr. Rudrasingh Jadeja and Rt.
							Rev. Bp. Gregory Karotemprel CMI.
						</p>
						<br/>
						<p>Lack of transport facility was one of the major problems in the District. Since Kutch is a drought-prone
							area, KVT also faced many difficulties in making the land fruitful one.
						</p>
						<br/>
						<p>KVT is in constant contact with the entire district with its 966 villages and 4 towns. KVT is known
							to everybody in this district including poor, middle class, the rich business community, people
							working in the educational field and so on. KVT has got good relationships with the Govt.
							departments and officers. KVT is implementing several Govt. Projects in this district.
							Among all the NGOs in Kutch working with the Govt., KVT is appointed as a leading PIA
							(Project Implementing Agency).</p>
						<br/>
					</div>
				</div>
			</div>

			<div className="row">

				<div className="col-md-12">
					<div className="full-sec-content"><br/>
						<h4>Achievements of K.V.T. :</h4>
					</div>
				</div>

				<div className="col-sm-12">
					<div className="full-sec-content">
						<br/>
						<p>23rd of June 1986 Fr. George Kunnath with three CMC Sisters (Sr. Antonitta, Sr. Johnet & Sr. Metty)
							together with 16 blind children arrived in K.V.T. This day can be considered as the starting of
							hostels in KVT.
						</p>
						<br/>
						<p>Our new school building was inaugurated by Shri Trivedi, Gujarat Governor in the year 1986.
						</p>
						<br/>
						<p>Kutch Vikas Trust Hospital was started in 1987. Using 8 classrooms of the school building,
							which were not in use at that time, we started the eye hospital.
						</p>
						<br/>
						<p>We started CBR project in 1987. A batch of 10 field workers was trained by Mr. Robert Jacquels,
							CBR expert of CBM, Trichinapally. They were given new mopeds for traveling in the villages.
						</p>
						<br/>
						<p>The second community of Sisters, Presentation Congregation of Calicut was started in 1988.
							The members of the first community were Sr. Jaya, Sr. Jeena, Sr. Rosamma, Sr. Merlin and Sr.
							Thesere. Sr. Jeena and Sr. Therese started working in KVT together with CMC Sisters from
							1986 July onwards.</p>
						<br/>
						<p>Hostel units were completed in 1988.</p>
						<br/>
						<p>Fours Sisters from Savina Congregation started in KVT as a community in 7th June 1993.
							They are Sr. Thresiamma Kaniyamkunnel, Sr. Clara Thundiyil & Sr. Elsy Njarackal.</p>
						<br/>
						<p>A new building for the hospital was constructed in 1991-1992 and inaugurated by Shri
							M.M. Jacob, Former Minister of Home, Delhi and Present Governor of Meghalaya inaugurated
							K.V.T. Hospital on 5-11-1992. Mr. C.D. Patel, Gujarat Home Minister, Mr. Haribhai Patel,
							Gujarat Minister, Mr. Randhawa IAS, Collector of Kutch, and Bishop Gregory, the Catholic
							Bishop of Rajkot Diocese were also present on the occasion function.</p>
						<br/>
						<p>Patients from villages had serious inconvenience to reach KVT hospital for the eye
							examination and get back to their village on the same day. Therefore we started
							an Eye Examination Centre (O.P.D.) in Bhuj town on 08-01-1990.</p>
						<br/>
						<p>After establishing KVT and its various activities we started expanding work by
							establishing the similar center in Bhachau (1993), Nakhatrana (1994) and Naliya (1999).</p>
						<br/>
						<p>St. Ann’s Community: Sr. Annie Lonnappan, Sr. Treesa & Sr. Shiny were
							sisters who came here during the year 1998 and started the St. Ann’s Community.</p>
						<br/>
					</div>
				</div>
			</div>


			<div className="row">

				<div className="col-md-12">
					<div className="full-sec-content"><br/>
						<h4>We have received the following awards and recognition :</h4>
					</div>
				</div>

				<div className="col-sm-12">
					<div className="full-sec-content">
						<br/>
						<p>
							<ul>
								<li>National Award for Best Employer of the Disabled – 2006.</li>
								<li>Gujarat Govt. Award for Best Employer of the Disabled – 2004</li>
								<li>Gujarat Govt. Award for the Best Service Organization working for the Disabled – 2001.</li>
								<li>Van Pandit Award from Gujarat Forest Department.</li>
								<li>Bharat Excellence Award from New Delhi.</li>
								<li>Ragasudha Award from Bombay.</li>
							</ul>
						</p>
						<br/>
					</div>
				</div>
			</div>

			<div className="row">

				<div className="col-md-12">
					<div className="full-sec-content"><br/>
						<h4>The Founding Members of Kutch Vikas Trust :</h4>
					</div>
				</div>

				<div className="col-sm-12">
					<div className="full-sec-content">
						<br/>
						<p>
							<ul>
								<li>Rt. Rev. Dr. Jonas Thaliath CMI,<br/>
									Catholic Bishop of Rajkot.</li>
								<li>Rev. Fr. Mathew Kalathoor CMI,<br/>
									Parish Priest,<br/>
									Sector 11, Plot 90,<br/>
									Gandhidham.</li>
								<li>Rev. Fr. Davis Manadan CMI</li>
							</ul>
						</p>
						<br/>
					</div>
				</div>
			</div>


		</div>
	</section>


   </div>
);

export default KutchVikasTrust;