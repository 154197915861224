import React from 'react';

import imageOne from '../../../assets/img/common/inners/PREVENTION-OF-BLINDNESS.jpg';
import imageTwo from '../../../assets/img/common/inners/MARHARSHI-ASTAVAKR-YOJANA.jpg';

import bgImage from '../../../assets/img/common/imgPageHeader.jpg';

const CommunityBasedRehab = () => (
   <div>
      <section className="inner-header" style={{backgroundImage: `url(${bgImage})`}}>
		<div className="container">
			<div className="row">
				<div className="col-md-12 sec-title colored text-center">
					<h2>Activities</h2>
					<ul className="breadcumb">
						<li><a href="index">Home</a></li>
						<li><i className="fa fa-angle-right"></i></li>
						<li><span>Community-Based Rehab</span></li>
					</ul>
					<span className="decor"><span className="inner"></span></span>
				</div>
			</div>
		</div>
	</section>


	<section className="event-feature sec-padding pb_60" data-bg-color="#fafafa">
		<div className="container">
			<div className="row">


				<div className="col-sm-12">
					<div className="event border-1px mb_30">
						<div className="row">
							<div className="col-sm-5">
								<div className="event-thumb">
									<div className="thumb">
										<img className="full-width" src="/img/common/inners/PREVENTION-OF-BLINDNESS.jpg" alt="COMMUNITY EYE HEALTH CARE SERVICES"/>
									</div>
								</div>
							</div>
							<div className="col-sm-7">
								<div className="event-content p_20 pl_0 pt_5">
									<h3 className="event-title">COMMUNITY EYE HEALTH CARE SERVICES</h3>
									<p className="mb_20">It is a comprehensive programme, wherein eye care services along with rehabilitation 
										are given priority. So far we have given assistance to more than 650 disabled people in the 
										villages, like economic rehabilitation, community rehabilitation, all the assistance from 
										the Government schemes.</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="col-sm-12">
					<div className="event border-1px mb_30">
						<div className="row">
							<div className="col-sm-5">
								<div className="event-thumb">
									<div className="thumb">
										<img className="full-width" src="/img/common/inners/MARHARSHI-ASTAVAKR-YOJANA.jpg" alt="MARHARSHI ASTAVAKR YOJANA"/>
									</div>
								</div>
							</div>
							<div className="col-sm-7">
								<div className="event-content p_20 pl_0 pt_5">
									<h3 className="event-title">MARHARSHI ASTAVAKR YOJANA</h3>
									<p className="mb_20">
											Under this programme, the Taluka of Nakhatrana is covered with a comprehensive survey and all the 
											disabilities are covered. The Disabled are given all the necessary certificates, 
											passes and all the schemes are programmes of the Government and rehabilitation 
											and integration.</p>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>

		</div>
	</section>
   </div>
);

export default CommunityBasedRehab;