import React from 'react';
import { Link } from 'react-router-dom';

import HomePageEvents from './homepageevents.component';

const HomeComponent = () => {
	return (
		<div>

			<section className="rev_slider_wrapper">
				<div id="slider1" className="rev_slider" data-version="5.0">
					<ul>
						<li data-transition="parallaxvertical">
							<img src="img/common/sliders/banner1.jpg" alt="" width="1920" height="705" data-bgposition="top center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="1" />
							<div className="tp-caption sfl tp-resizeme thm-banner-h1 hide"
								data-x="left" data-hoffset="0"
								data-y="top" data-voffset="225"
								data-whitespace="nowrap"
								data-transform_idle="o:1;"
								data-transform_in="o:0"
								data-transform_out="o:0"
								data-start="500">
								&nbsp;
				    </div>
							<div className="tp-caption sfr tp-resizeme thm-banner-h1 heavy"
								data-x="left" data-hoffset="0"
								data-y="top" data-voffset="290"
								data-whitespace="nowrap"
								data-transform_idle="o:1;"
								data-transform_in="o:0"
								data-transform_out="o:0"
								data-start="1000">
								Kutch Vikas Trust
				    </div>
							<div className="tp-caption sfb tp-resizeme thm-banner-h3"
								data-x="left" data-hoffset="0"
								data-y="top" data-voffset="368"
								data-whitespace="nowrap"
								data-transform_idle="o:1;"
								data-transform_in="o:0"
								data-transform_out="o:0"
								data-start="1500">
								A better world of Equality, Equity, and Opportunity
				    </div>
							<div className="tp-caption sfb tp-resizeme thm-banner-p hide"
								data-x="left" data-hoffset="0"
								data-y="top" data-voffset="430"
								data-whitespace="nowrap"
								data-transform_idle="o:1;"
								data-transform_in="o:0"
								data-transform_out="o:0"
								data-start="2000">
								&nbsp;
				    </div>
							<div className="tp-caption sfl tp-resizeme"
								data-x="left" data-hoffset="0"
								data-y="top" data-voffset="505"
								data-whitespace="nowrap"
								data-transform_idle="o:1;"
								data-transform_in="o:0"
								data-transform_out="o:0"
								data-start="2300">
								<Link to="kutch-vikas-trust" className="thm-btn">Know More</Link>
							</div>
							<div className="tp-caption sfr tp-resizeme hide"
								data-x="left" data-hoffset="185"
								data-y="top" data-voffset="505"
								data-whitespace="nowrap"
								data-transform_idle="o:1;"
								data-transform_in="o:0"
								data-transform_out="o:0"
								data-start="2600">
								<Link to="kutch-vikas-trust" className="thm-btn inverse">Know More</Link>
							</div>
						</li>
						<li data-transition="parallaxvertical">
							<img src="img/common/sliders/banner2.jpg" alt="" width="1920" height="705" data-bgposition="top center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="2" />
							<div className="tp-caption sfl tp-resizeme thm-banner-h1 blue-bg hide"
								data-x="left" data-hoffset="0"
								data-y="top" data-voffset="249"
								data-whitespace="nowrap"
								data-transform_idle="o:1;"
								data-transform_in="o:0"
								data-transform_out="o:0"
								data-start="500">
								&nbsp;
				    </div>
							<div className="tp-caption sfr tp-resizeme thm-banner-h1 heavy black-bg"
								data-x="left" data-hoffset="0"
								data-y="top" data-voffset="318"
								data-whitespace="nowrap"
								data-transform_idle="o:1;"
								data-transform_in="o:0"
								data-transform_out="o:0"
								data-start="1000">
								Kutch Vikas Trust
				    </div>
							<div className="tp-caption sfb tp-resizeme thm-banner-h3"
								data-x="left" data-hoffset="0"
								data-y="top" data-voffset="386"
								data-whitespace="nowrap"
								data-transform_idle="o:1;"
								data-transform_in="o:0"
								data-transform_out="o:0"
								data-start="1500">
								Working for the welfare and advantage of the weaker sections in the society
				    </div>
							<div className="tp-caption sfl tp-resizeme"
								data-x="left" data-hoffset="0"
								data-y="top" data-voffset="450"
								data-whitespace="nowrap"
								data-transform_idle="o:1;"
								data-transform_in="o:0"
								data-transform_out="o:0"
								data-start="2300">
								<Link to="kutch-vikas-trust" className="thm-btn">Know More</Link>
							</div>
							<div className="tp-caption sfr tp-resizeme hide"
								data-x="left" data-hoffset="185"
								data-y="top" data-voffset="450"
								data-whitespace="nowrap"
								data-transform_idle="o:1;"
								data-transform_in="o:0"
								data-transform_out="o:0"
								data-start="2600">
								<Link to="/" className="thm-btn inverse">Learn More</Link>
							</div>
						</li>
					</ul>
				</div>
			</section>

			<section className="sec-padding">
				<div className="container">
					<div className="row">
						<div className="col-md-8 col-md-offset-2 promote-project text-center">
							<h3>About Us</h3>
							<div className="sec-title colored text-center">
								<span className="decor">
									<span className="inner"></span>
								</span>
							</div>
							<h2>KUTCH VIKAS TRUST</h2>
							<p>Kutch Vikas Trust is a charitable organization registered with the government.
							It is registered under Bombay Public Trust Act, 1950 Reg. No. E644/Dt. 27-11-1980.
							This is non-political, non-profit and non-sectarian in nature. The services of
							Kutch Vikas Trust are available to all sections of the people, without any
                        discrimination of caste, religion and social status.</p>
							<p>The main activity of Kutch Vikas Trust is welfare activities for the disabled.
							We started these activities 20 years ago. This includes Formal Education of the
							Disabled Children in integrated setup, Vocational Training, Rehabilitation Services,
							Placement and Employment Services, Marriage Bureaus for the Disabled, Prevention of
							Disability, Comprehensive Services to the disabled etc. Majority out of the 300 staff
                         members in Kutch Vikas Trust are engaged in Welfare Activities of the Disabled.</p>
							<Link to="kutch-vikas-trust" className="thm-btn inverse">Know More</Link>
						</div>
					</div>
				</div>
			</section>

			<section className="call-to-action" >
				<div className="container-fluid">
					<div className="clearfix">
						<div className="call-to-action-corner col-md-6" style={{ backgroundImage: 'url(img/common/imgHomeSect1.jpg)' }}>
							<div className="single-call-to-action">
								<div className="icon-box">
									<div className="inner-box">
										<i className="flaticon-circle"></i>
									</div>
								</div>
								<div className="content-box">
									<h3>OUR VISION</h3>
									<p>A better world of Equality, Equity, and Opportunity for the less privileged, marginalized and poor.</p>
								</div>
							</div>
						</div>
						<div className="call-to-action-center col-md-6" style={{ backgroundImage: 'url(img/common/imgHomeSect2.jpg)' }}>
							<div className="single-call-to-action">
								<div className="icon-box">
									<div className="inner-box">
										<i className="flaticon-social"></i>
									</div>
								</div>
								<div className="content-box">
									<h3>OUR MISSION</h3>
									<p>Working for the welfare and advantage of the weaker sections in the society, like disabled, elderly, women, children and poor through development and empowerment.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="event-feature sec-padding" data-bg-color="#fafafa">
				<div className="container">
					<div className="row">
						<HomePageEvents />
						<div className="col-md-6 hidden-md hidden-sm">
							<div className="img-masonary">
								<div className="img-w1">
									<img src="img/common/imgHomeSect3.jpg" height="450" width="280" alt="" />
								</div>
								<div className="img-w1 img-h1">
									<img src="img/common/imgHomeSect4.jpg" height="450" width="280" alt="" />
								</div>
								<div className="img-w1 img-h1">
									<img src="img/common/imgHomeSect5.jpg" height="450" width="280" alt="" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

		</div>

	)
};

export default HomeComponent;