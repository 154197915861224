import React from 'react';

import imageOne from '../../../assets/img/common/inners/COMMUNITY-BASED-REHABILITATION.jpg';
import imageTwo from '../../../assets/img/common/inners/CHILD-CARE-CENTRES.jpg';

import bgImage from '../../../assets/img/common/imgPageHeader.jpg';

const CenterForMultiDisabled = () => (
   <div>
      <section className="inner-header" style={{backgroundImage: `url(${bgImage})`}}>
		<div className="container">
			<div className="row">
				<div className="col-md-12 sec-title colored text-center">
					<h2>Activities</h2>
					<ul className="breadcumb">
						<li><a href="index">Home</a></li>
						<li><i className="fa fa-angle-right"></i></li>
						<li><span>Centre for the Multi-disabled</span></li>
					</ul>
					<span className="decor"><span className="inner"></span></span>
				</div>
			</div>
		</div>
	</section>


	<section className="event-feature sec-padding pb_60" data-bg-color="#fafafa">
		<div className="container">
			<div className="row">


				<div className="col-sm-12">
					<div className="event border-1px mb_30">
						<div className="row">
							<div className="col-sm-5">
								<div className="event-thumb">
									<div className="thumb">
										<img className="full-width" src="/img/common/inners/COMMUNITY-BASED-REHABILITATION.jpg" alt="COMMUNITY BASED REHABILITATION (C. B. R.) FOR DEAF-BLIND & MULTI-DISABLED"/>
									</div>
								</div>
							</div>
							<div className="col-sm-7">
								<div className="event-content p_20 pl_0 pt_5">
										<h3 className="event-title">COMMUNITY BASED REHABILITATION (C. B. R.) FOR DEAF-BLIND & MULTI-DISABLED
											</h3>
										<p className="mb_20">
												There are a few who can neither hear nor see what is happening around and their life is lived miserably, 
												with parents and relatives disowning many of them. Some of them are multi-disabled, with spastics, 
												mental retardation, physical disability, deafness, and blindness. K. V. T. with assistance from 
												Sense International has initially set up the center to look after the people with multiple 
												disabilities.</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="col-sm-12">
					<div className="event border-1px mb_30">
						<div className="row">
							<div className="col-sm-5">
								<div className="event-thumb">
									<div className="thumb">
										<img className="full-width" src="/img/common/inners/CHILD-CARE-CENTRES.jpg" alt="CHILD CARE CENTRES"/>
									</div>
								</div>
							</div>
							<div className="col-sm-7">
								<div className="event-content p_20 pl_0 pt_5">
									<h3 className="event-title">CHILD CARE CENTRES</h3>
									<p className="mb_20">
											We have 12 children who are given in-house training and about 15 children who receive assistance at 
											their homes, through C. B. R. field workers. We also conduct the simultaneous survey and it is 
											heartening to note that there are more such people than we expected.</p>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>

		</div>
	</section>

   </div>
);
export default CenterForMultiDisabled;