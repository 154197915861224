import React from 'react';

import imageOne from '../../../assets/img/common/funding/government/imgdistrictRuralDevelopmentAuthority.jpg';
import imageTwo from '../../../assets/img/common/funding/government/imgCapart.jpg';
import imageThree from '../../../assets/img/common/funding/government/imgHudco.jpg';
import imageFour from '../../../assets/img/common/funding/government/imgSocialDiffenceDepartment.jpg';
import imageFive from '../../../assets/img/common/funding/government/imgDepWomenAndChildren.jpg';
import imageSix from '../../../assets/img/common/funding/government/imgGCERT.jpg';
import imageSeven from '../../../assets/img/common/funding/government/imgMaharshiAshtavakraYojana.jpg';
import imageEight from '../../../assets/img/common/funding/government/imgTIFAC.jpg';
import bgImage from '../../../assets/img/common/imgPageHeader.jpg';

const GovAgencies = () => (
   <div>
      <section className="inner-header" style={{backgroundImage: `url(${bgImage})`}}>
         <div className="container">
            <div className="row">
               <div className="col-md-12 sec-title colored text-center">
                  <h2>Gallery</h2>
                  <ul className="breadcumb">
                     <li><a href="index.html">Home</a></li>
                     <li><i className="fa fa-angle-right"></i></li>
                     <li><span>Government Agencies</span></li>
                  </ul>
                  <span className="decor"><span className="inner"></span></span>
               </div>
            </div>
         </div>
      </section>

      <section className="gallery-section pb_70">
         <div className="container">

            <div className="clearfix">

               <div className="image-box">
                  <div className="inner-box">
                     <figure className="image">
                        <img src="/img/common/funding/government/imgdistrictRuralDevelopmentAuthority.jpg" alt="District Rural Development Authority (State Government)" title="District Rural Development Authority (State Government)" />
                        <figcaption className="pt-5 text-center">District Rural Development Authority (State Government)</figcaption>
                     </figure>
                  </div>
               </div>

               <div className="image-box">
                  <div className="inner-box">
                     <figure className="image">
                        <img src="/img/common/funding/government/imgCapart.jpg" alt="Capart (Central Government)" title="Capart (Central Government)" />
                        <figcaption className="pt-5 text-center">Capart (Central Government)</figcaption>
                     </figure>
                  </div>
               </div>

               <div className="image-box">
                  <div className="inner-box">
                     <figure className="image">
                        <img src="/img/common/funding/government/imgHudco.jpg" alt="Hudco (Central Government)" title="Hudco (Central Government)" />
                        <figcaption className="pt-5 text-center">Hudco (Central Government)</figcaption>
                     </figure>
                  </div>
               </div>

               <div className="image-box">
                  <div className="inner-box">
                     <figure className="image">
                        <img src="/img/common/funding/government/imgSocialDiffenceDepartment.jpg" alt="Social Defense Department (State Government)" title="Social Defense Department (State Government)" />
                        <figcaption className="pt-5 text-center">Social Defense Department (State Government)</figcaption>
                     </figure>
                  </div>
               </div>

               <div className="image-box">
                  <div className="inner-box">
                     <figure className="image">
                        <img src="/img/common/funding/government/imgDepWomenAndChildren.jpg" alt="Department of Women & Child Empowerment (State Government)" title="Department of Women & Child Empowerment (State Government)" />
                        <figcaption className="pt-5 text-center">Department of Women & Child Empowerment (State Government)</figcaption>
                     </figure>
                  </div>
               </div>

               <div className="image-box">
                  <div className="inner-box">
                     <figure className="image">
                        <img src="/img/common/funding/government/imgGCERT.jpg" alt="G. C. E. R. T" title="G. C. E. R. T" />
                        <figcaption className="pt-5 text-center">G. C. E. R. T</figcaption>
                     </figure>
                  </div>
               </div>

               <div className="image-box">
                  <div className="inner-box">
                     <figure className="image">
                        <img src="/img/common/funding/government/imgMaharshiAshtavakraYojana.jpg" alt="Maharshi Ashtavakra Yojana (State Government)" title="Maharshi Ashtavakra Yojana (State Government)" />
                        <figcaption className="pt-5 text-center">Maharshi Ashtavakra Yojana (State Government)</figcaption>
                     </figure>
                  </div>
               </div>

               <div className="image-box">
                  <div className="inner-box">
                     <figure className="image">
                        <img src="/img/common/funding/government/imgTIFAC.jpg" alt="T. I. F. A. C. (Central Government)" title="T. I. F. A. C. (Central Government)" />
                        <figcaption className="pt-5 text-center">T. I. F. A. C. (Central Government)</figcaption>
                     </figure>
                  </div>
               </div>

            </div>
         </div>
      </section>
   </div>
);
export default GovAgencies;