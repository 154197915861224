import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';


import HeaderComponent from './components/Header/header.component';
import FooterComponent from './components/Footer/footer.component';

import HomeComponent from './components/HomePage/homepage.compoent';
import KutchVikasTrust from './components/pages/aboutus/kutchvikastrust.component';
import FrGeorge from './components/pages/aboutus/frgeorgekunnath.component';
import PhotoGalleryComponent from './components/pages/aboutus/photogalary.component';
import StatisticsComponent from './components/pages/aboutus/statistics.component';
import AwardsComponent from './components/pages/aboutus/awards.component';
import EventComponent from './components/pages/aboutus/events.component';
import NewsComponent from './components/pages/aboutus/news.component';

import EducationForDisabled from './components/pages/activities/educationfordisabled.component';
import HealthCareActivities from './components/pages/activities/healthcareactivities.component';
import CommunityBasedRehab from './components/pages/activities/communitybasedrehab.component';
import CenterForMultiDisabled from './components/pages/activities/centerformultidisabled.component';
import OtherWelfareActivities from './components/pages/activities/otherwelfareactivitice.component';

import MaharshyJojana from './components/pages/projects/maharshy.component';
import WatersheadComponent from './components/pages/projects/watershead.component';
import CESAnjarProject from './components/pages/projects/cesanjarprojec.jcomponent';

import GovAgencies from './components/pages/fundingagensis/governmentngo.component';
import NationalAgencies from './components/pages/fundingagensis/nationalngo.component';
import InterNationalAgencies from './components/pages/fundingagensis/internationalngo.component';

import AnAppealComponent from './components/pages/anappeal/anappeal.component';



import './App.css';


function App() {
  return (
    <div className="App">
     <HeaderComponent/>
     <Switch>
          <Route exact path='/' component={HomeComponent}></Route>

          <Route exact path='/about-us/kutch-vikas-trust' component={KutchVikasTrust}></Route>
          <Route exact path='/about-us/fr-george-kunnath' component={FrGeorge}></Route>
          <Route exact path='/about-us/photo-gallery' component={PhotoGalleryComponent}></Route>
          <Route exact path='/about-us/statistics' component={StatisticsComponent}></Route>
          <Route exact path='/about-us/awards' component={AwardsComponent}></Route>
          <Route exact path='/about-us/events' component={EventComponent}></Route>
          <Route exact path='/about-us/news' component={NewsComponent}></Route>

          <Route exact path='/activities/education-for-the-disabled' component={EducationForDisabled}></Route>
          <Route exact path='/activities/health-care-activities' component={HealthCareActivities}></Route>
          <Route exact path='/activities/community-based-rehab' component={CommunityBasedRehab}></Route>
          <Route exact path='/activities/center-for-the-multi-disabled' component={CenterForMultiDisabled}></Route>
          <Route exact path='/activities/other-welfare-activities' component={OtherWelfareActivities}></Route>

          <Route exact path='/projects/maharshi-ashtavakra-yogna' component={MaharshyJojana}></Route>
          <Route exact path='/projects/watershed-management' component={WatersheadComponent}></Route>
          <Route exact path='/projects/ces-anjar-project' component={CESAnjarProject}></Route>

          <Route exact path='/funding-agenesis/government-agencies' component={GovAgencies}></Route>
          <Route exact path='/funding-agenesis/national-ngos' component={NationalAgencies}></Route>
          <Route exact path='/funding-agenesis/international-ngos' component={InterNationalAgencies}></Route>

          <Route exact path='/an-appeal' component={AnAppealComponent}></Route>

        </Switch>
     <FooterComponent/>
    </div>
  );
}

export default App;
