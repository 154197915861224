import React from 'react';

import imageOne from '../../../assets/img/common/inners/PREVENTION-OF-BLINDNESS.jpg';
import bgImage from '../../../assets/img/common/imgPageHeader.jpg';

const CESAnjarProject = () => (
   <div>
      <section className="inner-header" style={{backgroundImage: `url(${bgImage})`}}>
         <div className="container">
            <div className="row">
               <div className="col-md-12 sec-title colored text-center">
                  <h2>Projects</h2>
                  <ul className="breadcumb">
                     <li><a href="index">Home</a></li>
                     <li><i className="fa fa-angle-right"></i></li>
                     <li><span>CES, Anjar Project</span></li>
                  </ul>
                  <span className="decor"><span className="inner"></span></span>
               </div>
            </div>
         </div>
      </section>


      <section className="event-feature sec-padding pb_60" data-bg-color="#fafafa">
         <div className="container">
            <div className="row">
               <div className="col-sm-12">
                  <div className="event border-1px mb_30">
                     <div className="row">
                        <div className="col-sm-5">
                           <div className="event-thumb">
                              <div className="thumb">
                                 <img className="full-width" src="/img/common/inners/PREVENTION-OF-BLINDNESS.jpg" alt="CES, Anjar Project" />
                              </div>
                           </div>
                        </div>
                        <div className="col-sm-7">
                           <div className="event-content p_20 pl_0 pt_5">
                              <h3 className="event-title">CES, Anjar Project</h3>
                              <p className="mb_20">This project proposes to initiate a Community Eye Health project (C. B. R. and I. E.)
                              in one of the most backward areas in the country hence to enhance the quality of eye care
                              services in this remote area. Through this project, the partner plans to create awareness
                              among the people on eye health and ensure community participation through training of
                              volunteers. Rehabilitation of irreversibly blind persons including children is also
										planned under this project.</p>
                              <p className="mb_20">During the project period, the partner will also coordinate with
                              Government to facilitate Vitamin A supplementation and immunization programmes
											in the project area.</p>
                           </div>
                        </div>
                     </div>

                     <div className="row">

                        <div className="col-md-12">
                           <div className="full-sec-content"><br />
                              <h4>THE MAIN ACTIVITIES AND ITS OUTPUT IN NEXT THREE YEARS ARE AS FOLLOWS</h4>
                           </div>
                        </div>

                        <div className="col-sm-12">
                           <div className="full-sec-content">
                              <br />
                              <p>
                                 <ul>
                                    <li>Awareness generation on eye health among the target population.</li>
                                    <li>60 screening camps and screening of 12,000 people.</li>
                                    <li>Referred 3,000 poor patients for IOL surgeries.</li>
                                    <li>Screening of 15,000 school children through school screening Programme.</li>
                                    <li>Referred 60 Paediatric patients for IOL surgeries.</li>
                                    <li>Referred 300 Glaucoma Patients for Treatment.</li>
                                    <li>Follow up of postoperative cases.</li>
                                    <li>Facilitating Vitamin A supplementation and immunization programmes.</li>
                                    <li>Rehabilitation of 200 blind persons</li>
                                    <li>Integrated education for Blind and low vision children in the project area.</li>
                                 </ul>
                              </p>
                              <br />
                           </div>
                        </div>
                     </div>

                     <div className="row">

                        <div className="col-md-12">
                           <div className="full-sec-content"><br />
                              <h4>OBJECTIVES</h4>
                           </div>
                        </div>

                        <div className="col-sm-12">
                           <div className="full-sec-content">
                              <br />
                              <p>
                                 <ul>
                                    <li>To create awareness of primary eye care among the target population by 2006.</li>
                                    <li>To prepare 500 community volunteers from the project area to ensure community participation
														in primary eye health, in three years time.</li>
                                    <li>To provide eye care services to 15,000 school age children in the project area.</li>
                                    <li>To provide free and subsidize eye care services through 60 screening camps to 12000
														adults in three years from the project area.</li>
                                    <li>To ensure the Vitamin A supplementation and immunization programmer reaches all children
														in the project area over the three years.</li>
                                    <li>To provide rehabilitative services (Mobility training, Economical and social rehabilitation) to
														all irreversible persons including children in the project area in three years.</li>
                                    <li>To provide integrated education services to all children with visual impairment in the project area.</li>
                                 </ul>
                              </p>
                              <br />
                           </div>
                        </div>
                     </div>
                     <div className="row">

                        <div className="col-md-12">
                           <div className="full-sec-content"><br />
                              <h4>SPECIFIC ACTIVITIES</h4>
                           </div>
                        </div>

                        <div className="col-sm-12">
                           <div className="full-sec-content">
                              <br />
                              <p>
                                 <ul>
                                    <li>CEH workers training in primary eye care and rehabilitation (Two modules)</li>
                                    <li>60 mass awareness programmes through cultural and folk media (20 programmes each year)</li>
                                    <li>Training of 300 school teachers in primary eye care from 90 schools</li>
                                    <li>Training of 150 PHC (primary health center) staff in primary eye health.</li>
                                    <li >Training of 100 SHG (self-help groups) leaders in primary eye care.</li>
                                    <li >Training of 300 Community health workers / traditional medical practitioners in primary eye health.</li>
                                    <li >120 Children’s eye screening events (40 events a year)</li>
                                    <li >Provision of spectacles to children</li>
                                    <li >60 Screening camps, 20 camps per year.</li>
                                    <li >Follow up of postoperative cases.</li>
                                    <li >Integrated education for the blind children(Appx.16).</li>
                                    <li >Rehabilitation of 150 irreversibility blind and low vision persons.</li>
                                    <li >Referral for Treatment (Both surgical and medicine) of patients at base hospital</li>
                                 </ul>
                              </p>
                              <br />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

         </div>
      </section>
   </div>
);
export default CESAnjarProject;