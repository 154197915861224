import React from 'react';

import imageOne from '../../../assets/img/common/awards/award3.jpg';
import imageTwo from '../../../assets/img/common/awards/Award1.jpg';
import imageThree from '../../../assets/img/common/awards/remarkable-service-to-theearthquake.jpg';
import imageFour from '../../../assets/img/common/awards/Bharat-Excellence.jpg';
import imageFive from '../../../assets/img/common/awards/Best-Employer-of-Disabled.jpg';

import bgImage from '../../../assets/img/common/imgPageHeader.jpg';

const AwardsComponent = () => (
   <div>
      <section className="inner-header" style={{backgroundImage: `url(${bgImage})`}}>
         <div className="container">
            <div className="row">
               <div className="col-md-12 sec-title colored text-center">
                  <h2>About Us</h2>
                  <ul className="breadcumb">
                     <li><a href="index">Home</a></li>
                     <li><i className="fa fa-angle-right"></i></li>
                     <li><span>Awards</span></li>
                  </ul>
                  <span className="decor"><span className="inner"></span></span>
               </div>
            </div>
         </div>
      </section>


      <section className="event-feature sec-padding pb_60" data-bg-color="#fafafa">
         <div className="container">
            <div className="row">
               <div className="col-sm-6 col-md-4">
                  <div className="event border-1px mb_30">
                     <div className="row">
                        <div className="col-sm-12">
                           <div className="event-thumb">
                              <div className="thumb">
                                 <img className="full-width" src="/img/common/awards/award3.jpg" alt="Van Pandit Award" />
                              </div>
                           </div>
                        </div>
                        <div className="col-sm-12">
                           <div className="event-content p_20">
                              <h4 className="event-title">1997-1998</h4>
                              <p className="mb-0"><strong>Van Pandit Award</strong> presented by Government of Gujarat.</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <div className="col-sm-6 col-md-4">
                  <div className="event border-1px mb_30">
                     <div className="row">
                        <div className="col-sm-12">
                           <div className="event-thumb">
                              <div className="thumb">
                                 <img className="full-width" src="/img/common/awards/Award1.jpg" alt="Outstanding service of the disabled" />
                              </div>
                           </div>
                        </div>
                        <div className="col-sm-12">
                           <div className="event-content p_20">
                              <h4 className="event-title">2001</h4>
                              <p className="mb-0">Award for <strong>Outstanding service of the disabled</strong> from the Government of Gujarat for the year 2001.</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <div className="col-sm-6 col-md-4">
                  <div className="event border-1px mb_30">
                     <div className="row">
                        <div className="col-sm-12">
                           <div className="event-thumb">
                              <div className="thumb">
                                 <img className="full-width" src="/img/common/awards/remarkable-service-to-theearthquake.jpg" alt="remarkable service to the earthquake affected people" />
                              </div>
                           </div>
                        </div>
                        <div className="col-sm-12">
                           <div className="event-content p_20">
                              <h4 className="event-title">2001-2002</h4>
                              <p className="mb-0">Award for <strong>remarkable service to the earthquake affected people</strong> in 2001 -2002 presented by Dr. B. L. Mungkar, Vice Chancellor, Mumbai University sponsored by Ragasudha, Mumbai.</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <div className="col-sm-6 col-md-4">
                  <div className="event border-1px mb_30">
                     <div className="row">
                        <div className="col-sm-12">
                           <div className="event-thumb">
                              <div className="thumb">
                                 <img className="full-width" src="/img/common/awards/Bharat-Excellence.jpg" alt="Bharat Excellence" />
                              </div>
                           </div>
                        </div>
                        <div className="col-sm-12">
                           <div className="event-content p_20">
                              <h4 className="event-title">2002-2003</h4>
                              <p className="mb-0">Award for <strong>Bharat Excellence</strong> presented by the Ex-chief Election commissioner Mr. G. Krishnamurthy sponsored by Friendship Forum of India.</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <div className="col-sm-6 col-md-4">
                  <div className="event border-1px mb_30">
                     <div className="row">
                        <div className="col-sm-12">
                           <div className="event-thumb">
                              <div className="thumb">
                                 <img className="full-width" src="/img/common/awards/Best-Employer-of-Disabled.jpg" alt="Best Employer of Disabled" />
                              </div>
                           </div>
                        </div>
                        <div className="col-sm-12">
                           <div className="event-content p_20">
                              <h4 className="event-title">2004-2005</h4>
                              <p className="mb-0">Award for <strong>Best Employer of Disabled</strong> from the government of Gujarat for the year 2004.</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>


   </div>
);

export default AwardsComponent;