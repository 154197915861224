import React from 'react';

import imageOne from '../../../assets/img/common/inners/Watershed-Management.jpg';
import bgImage from '../../../assets/img/common/imgPageHeader.jpg';


const WatersheadComponent = () => (
   <div>
      <section className="inner-header" style={{backgroundImage: `url(${bgImage})`}}>
         <div className="container">
            <div className="row">
               <div className="col-md-12 sec-title colored text-center">
                  <h2>Projects</h2>
                  <ul className="breadcumb">
                     <li><a href="index">Home</a></li>
                     <li><i className="fa fa-angle-right"></i></li>
                     <li><span>Watershed Management</span></li>
                  </ul>
                  <span className="decor"><span className="inner"></span></span>
               </div>
            </div>
         </div>
      </section>


      <section className="event-feature sec-padding pb_60" data-bg-color="#fafafa">
         <div className="container">
            <div className="row">
               <div className="col-sm-12">
                  <div className="event border-1px mb_30">
                     <div className="row">
                        <div className="col-sm-5">
                           <div className="event-thumb">
                              <div className="thumb">
                                 <img className="full-width" src="/img/common/inners/Watershed-Management.jpg" alt="Watershed Management" />
                              </div>
                           </div>
                        </div>
                        <div className="col-sm-7">
                           <div className="event-content p_20 pl_0 pt_5">
                              <h3 className="event-title">Watershed Management</h3>
                              <p className="mb_20">Another very ambitious and farsighted project that we have undertaken in the watershed
                              management programme with assistance and support from Government. We have so far completed more
                              than 35 villages and work is in progress in 15 other villages. Structures such as farm bunds
                              check dams, percolation pits and ponds, well recharging, deepening and cleaning of existing
										village ponds are some of the activities that are executed under this project.</p>
                              <p className="mb_20">The depleting of the water table can be arrested with this programme. The
                              participatory approach together with the people of the villages makes it more meaningful
											and people oriented.</p>
                              <p className="mb_20">Kutch Vikas Trust is also is the Lead Project Implementing Agency,
                              out of more than 50 NGO’s to execute and oversee the work in the entire district
                              of Kutch, and also assist and evaluate the programmes of other NGO’s
										implementing the work.</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

         </div>
      </section>

   </div>
);
export default WatersheadComponent;